<template>
  <div>
    <v-card flat>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container>
        <v-toolbar flat dark class="indigo lighten-3">
          <v-toolbar-title>Documents</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="documents"
            :loading="loading"
            fixed-header
            height="50vh"
            :items-per-page="-1"
          >
            <template v-slot:top>
              <v-text-field
                dense
                outlined
                v-model="search"
                placeholder="Search by text"
                prepend-icon="mdi-magnify"
              ></v-text-field>
              <v-btn
                block
                color="red"
                class="lighten-2"
                dark
                @click="openEditDocumentDialog"
              >
                <v-icon>mdi-plus</v-icon>Add Document
              </v-btn>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    color="orange"
                    v-on="on"
                    @click="editDocument(item)"
                  >mdi-pencil</v-icon>
                </template>
                <span>Edit Document</span>
              </v-tooltip> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    color="red"
                    v-on="on"
                    @click="deleteDocument(item)"
                  >mdi-delete</v-icon>
                </template>
                <span>Delete Document</span>
              </v-tooltip>
            </template>            
            <template v-slot:[`item.email_addresses`]="{ item }">
              <tr v-for="(e, i) in item.email_addresses" :key="i">
                <td>{{ e.emailaddress }}</td>
              </tr>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon 
                    color="orange"
                    @click="openEmailAddressEditingDialog(item)" 
                    v-on="on"
                  >mdi-pencil</v-icon>              
                </template>
                <span>Edit EmailAddress</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-container>
    </v-card>
    <!--emailAddressEditingDialog start-->
    <v-dialog v-model="emailAddressEditingDialog" width="70%">
      <v-card>
        <v-container>
          <v-toolbar class="indigo lighten-3" flat dark>
            <v-toolbar-title> Edit Email Address(es) </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
              <v-text-field
                v-model="emailAddressToBeAdded"
                outlined
                placeholder="Put EmailAddress to be added..."
                dense
                :rules="emailRules"
              ></v-text-field>
              <v-btn dark class="red my-2" block @click="addEmailAddress()"
                ><v-icon>mdi-plus</v-icon>add emailaddress</v-btn
              >
            </v-form>
            <v-data-table
              :headers="emailAddressHeaders"
              :items="emailAddresses"
            >
              <template v-slot:top>
                <v-toolbar flat color="indigo lighten-3" dark>
                  <v-toolbar-title>EmailAddresses</v-toolbar-title>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon @click="deleteEmailAddress(item)" v-on="on"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>Delete EmailAdress</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <!--emailAddressEditingDialog finish-->
    <!--editDocumentDialog start-->
    <v-dialog v-model="editDocumentDialog" width="70%">
      <v-card>
        <v-container>
          <v-form ref="form">
          <v-toolbar class="indigo lighten-3" flat dark>
            <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-select
              outlined
              dense
              label="Process Kind"
              v-model="processKind"
              :items="processKinds"
              @change="changeProcessKind"
            ></v-select>
            <v-select
            label="Document Category"
              outlined
              dense
              v-model="documentCategory"
              :items="documentCategories"
              item-text="name"
              item-value="id"
              :rules="rules" 
            ></v-select>
            <v-text-field
              v-model="title"
              outlined
              label="Document Title"
              dense
              :rules="rules"                
            ></v-text-field>
            <v-text-field
              v-model="description"
              outlined
              label="Description"
              dense
              :rules="rules"                
            ></v-text-field>
            <v-switch
              v-model="skip"
              :label="`Skip: ${skip.toString()}`"
            ></v-switch>    
            <v-text-field
              v-model="sequence"
              outlined
              label="Sequence"
              type="number"
              dense
              :rules="rules"                
            ></v-text-field>                      
          </v-card-text>
          <v-card-actions>
          <v-btn
            block
            color="red"
            class="lighten-2"
            dark
            @click="saveDocument"
            v-if="dialogTitle == 'Add Document'"
          >
            <v-icon>mdi-content-save</v-icon>save
          </v-btn>
          <v-btn
            block
            color="orange"
            class="lighten-2"
            dark
            @click="updateDocument"
            v-if="dialogTitle == 'Edit Document'"
          >
            <v-icon>mdi-content-save</v-icon>update
          </v-btn>          
          </v-card-actions>
        </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    <!--editDocumentDialog finish-->
  </div>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      loading: false,
      search: null,
      headers: [
        {
          text: "DocumentCategory",
          value: "document_category.name",
          sortable: true,
        },
        {
          text: "Title",
          value: "title",
          sortable: true,
        },
        {
          text: "Description",
          value: "description",
          sortable: true,
        },
        {
          text: "Skip",
          value: "skip",
          sortable: true,
        },
        {
          text: "Sequence",
          value: "sequence",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },                
        {
          text: "EmailAddresses",
          value: "email_addresses",
          sortable: true,
        },
      ],
      documents: [],
      emailAddressEditingDialog: false,
      emailAddressToBeAdded: null,
      rules: [(v) => !!v || "Required"],
      emailRules: [(v) => /.+@.+/.test(v) || "Invalid Email address"],
      emailAddressHeaders: [
        {
          text: "EmailAddress",
          value: "emailaddress",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: true,
        },
      ],
      emailAddresses: [],
      processKinds: [
        'shipping_line',
        'forwarder'
      ],
      documentIndex: null,
      editDocumentDialog: false,
      dialogTitle: null,
      processKind: 'shipping_line',
      documentCategories: [],
      documentCategory: null,
      title: null,
      description: null,
      sequence: 0,
      skip: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData: function () {
      this.overlay = true;
      this.loading = true;
      axios
        .post("/api/get_all_documents")
        .then((response) => {
          this.documents = response.data;
          // console.log(response.data);
          this.overlay = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.overlay = false;
          this.loading = false;
        });
    },
    loadData2: function (document_id) {
      this.overlay = true;
      this.loading = true;
      axios
        .post("/api/get_emailaddresses", {
          document_id: document_id,
        })
        .then((response) => {
          this.emailAddresses = response.data;
          this.overlay = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.overlay = false;
          this.loading = false;
        });
    },
    openEmailAddressEditingDialog: function (item) {
      this.documentIndex = item.id;
      this.emailAddressEditingDialog = true;
      this.loadData2(item.id);
    },
    addEmailAddress: function () {
      if (this.emailAddressToBeAdded == null) {
        return false;
      }
      if (!this.$refs.form.validate()) {
        return false;
      }
      this.emailAddresses.push({ emailaddress: this.emailAddressToBeAdded });

      this.axios
        .post("/api/insert_emailaddress", {
          document_id: this.documentIndex,
          emailaddress: this.emailAddressToBeAdded,
        })
        .then((response) => {
          this.emailAddressToBeAdded = null;
          this.loadData();
          this.loadData2(this.documentIndex);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteEmailAddress: function (item) {
      axios
        .post("/api/delete_emailaddress", {
          id: item.id,
        })
        .then((response) => {
          this.loadData();
          this.loadData2(item.document_id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDocumentCategories: function() {
      this.axios.post(
        '/api/get_document_categories',
        {
          process_kind: this.processKind,
        }
      ).then(response => {
        // console.log(response.data);
        this.documentCategories = response.data;
      }).catch(error => {
        console.log(error);
      });
    },
    changeProcessKind: function() {
      this.getDocumentCategories();
      this.documentCategory = null;
    },
    openEditDocumentDialog: function() {
      this.editDocumentDialog = true;
      this.dialogTitle = 'Add Document';
      this.sequence = 0;
      this.getDocumentCategories();
    },
    saveDocument: function() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.overlay = true,
      this.loading = true,
      this.axios.post( 
        '/api/save_document',
        {
          document_category_id: this.documentCategory,
          title: this.title,
          description: this.description,
          skip: this.skip,
          sequence: this.sequence,
        }
      ).then(response => {
        // console.log(response.data);
        this.editDocumentDialog = false;
        this.loadData();
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
        this.loading = false;
      });
    },
    // editDocument: function(item) {
    //   this.dialogTitle = 'Edit Document';
    //   this.editDocumentDialog = true;
    //   console.log(item);
    // },
    deleteDocument: function(item) {
      if(!confirm('Is it ok to delete the document?')) {
        return false;
      }
      this.overlay = true,
      this.loading = true,
      this.axios.post( 
        '/api/remove_document',
        {
          id: item.id
        }
      ).then(response => {
        console.log(response.data);
        this.loadData();
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
        this.loading = false;
      });      
    },
    // updateDocument: function() {

    // }
  },
};
</script>
