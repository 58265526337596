<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card flat>
      <v-container>
        <v-toolbar dark flat class="indigo lighten-3">
          <v-toolbar-title>Pending Documents for Forwarding</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :loading="loading"
          >
            <template v-slot:top>
              <v-text-field
                outlined
                dense
                v-model="search"
                placeholder="Serch by keyword..."
                prepend-icon="mdi-magnify"
              ></v-text-field>
            </template>

            <template v-slot:[`item.transaction`]="{ item }">
              {{ item.transaction.bl_number}}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                dark
                small
                class="orange lighten-3"
                @click="openDialog(item)"
                :disabled="$store.state.role === 'admin'"
              >
                <v-icon>mdi-reload</v-icon>Replace Document
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-container>
    </v-card>

    <!--DocumentReplacingDialog start-->
    <v-dialog width="60%" v-model="dialog">
      <v-card flat>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-container>
          <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title>Replace Document</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p>Name: {{ name }}</p>
            <p>Title: {{ title }}</p>
            <p>Description: {{ description }}</p>
            <v-file-input
              placeholder="Upload File..."
              v-model="userFiles"
              dense
              outlined
              chips
              multiple
              accept=".pdf"
              @change="addFile()"
            ></v-file-input>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <!--DocumentReplacingDialog finish-->
  </div>
</template>
<script>
export default {
  mounted() {
    this.loadData();
  },
  data() {
    return {
      overlay: false,
      loading: false,
      dialog: false,
      search: null,
      headers: [
        {
          text: "Name",
          value: "name",
          sortable: true,
        },
        {
          text: "BL Number",
          value: "transaction",
          sortable: true,
        },
        {
          text: "Status",
          value: "status",
          sortable: true,
        },
        {
          text: "Remark",
          value: "remark",
          sortable: true,
        },
        {
          text: "TransactionID",
          value: "transaction_id",
          sortable: true,
        },
        {
          text: "Title",
          value: "document.title",
          sortable: true,
        },
        {
          text: "Description",
          value: "document.description",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: true,
        },
      ],
      items: [],
      transaction_id: null,
      document_category_id: null,
      document_id: null,
      name: null,
      title: null,
      description: null,
      userFiles: [],
      formData: new FormData(),
    };
  },
  methods: {
    loadData: function () {
      this.overlay = true;
      this.loading = true;
      axios
        .post("/api/get_pending_documents", {
          user_id: this.$store.state.user_id,
          role: this.$store.state.role,
        })
        .then((response) => {
          // console.log(response.data);
          this.items = response.data;
          // if (this.$route.query.transaction_id) {
          //   this.search = this.$route.query.transaction_id;
          // }
          if (this.$route.params.transaction_id) {
            this.search = this.$route.params.transaction_id;
          }
          this.overlay = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.overlay = false;
          this.loading = false;
        });
    },
    openDialog: function (item) {
      this.transaction_id = item.transaction_id;
      this.document_category_id = item.document_category_id;
      this.document_id = item.document_id;
      this.name = item.name;
      this.title = item.document.title;
      this.description = item.document.description;
      this.dialog = true;
    },
    addFile: function () {
      if (!this.userFiles.length) {
        return false;
      }

      this.overlay = true;

      this.formData = new FormData();

      let i = 0;

      for (var file of this.userFiles) {
        this.formData.append("file" + i, file);
        i++;
      }
      this.formData.append("agent_code", this.$store.state.agent_code);
      this.formData.append("title", this.title);
      this.formData.append("process_kind", 'forwarder');
      this.formData.append("transaction_id", this.transaction_id);
      this.formData.append("description", this.description);
      this.formData.append("document_id", this.document_id);
      this.formData.append("email", this.$store.state.email);
      this.formData.append("status", "not yet started");
      // this.formData.append("pending_started_at", "NULL");
      // this.formData.append("remark", "NULL");

      this.axios
        .post(
          "/api/replaced_pending_document",
          this.formData,
          {
            onUploadProgress: this.onUpload,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          if (response.status === 200) {
            this.overlay = false;
            this.userFiles = [];
            this.dialog = false;
            this.loadData();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
