<template>
  <div>
    <v-card>
      <v-container>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :search="search"
          fixed-header
          height="50vh"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:top>
            <v-toolbar flat color="indigo lighten-3" dark>
              <v-toolbar-title>Users</v-toolbar-title>
            </v-toolbar>
            <v-text-field
              v-model="search"
              label="search"
              outlined
              class="my-3"
              append-icon="mdi-magnify"
              dense
            ></v-text-field>
          </template>
          <!-- <template v-slot:[`item.role`]="{ item }">
            {{ item.role
            }}<v-icon color="orange" @click="editRole(item)">mdi-pencil</v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="red" @click="disableUser(item)"
              >mdi-account-off</v-icon
            >
          </template> -->
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            total-visible="10"
          ></v-pagination>
          <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div>
      </v-container>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      loading: false,
      search: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 30,
      headers: [
        {
          text: "Name",
          value: "name",
          sortable: true,
        },
        {
          text: "Email",
          value: "email",
          sortable: true,
        },
        {
          text: "Org.",
          value: "organization",
          sortable: true,
        },
        {
          text: "AgentCode",
          value: "agent_code",
          sortable: true,
        },
        {
          text: "Role",
          value: "role",
          sortable: true,
        },
        {
          text: "SuperUser",
          value: "super_user",
          sortable: true,
        },        
        {
          text: "Actions",
          value: "actions",
          sortable: true,
        },
      ],
      items: [],
    };
  },
  created() {
    this.overlay = true;
    this.loading = true;
    this.axios
      .post("/api/get_users", {})
      .then((response) => {
        this.items = response.data;
        this.overlay = false;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.overlay = false;
        this.loading = false;
      });
  },
  methods: {
    // editRole: function (item) {
    //   alert("Under construction...");
    // },
    // disableUser: function (item) {
    //   alert("Under construction...");
    // },
  },
};
</script>
