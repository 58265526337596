<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-2">
            <v-toolbar color="black" dark flat>
              <v-toolbar-title>Provide your Emailaddress</v-toolbar-title>
            </v-toolbar>
            <v-form ref="form">
              <v-card-text>
                <v-text-field
                  label="Email"
                  name="email"
                  prepend-icon="email"
                  type="text"
                  v-model="email"
                  :rules="emailRules"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="black" dark @click="sendOTP">SEND OTP</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      overlay: false,
      email: null,
      rules: [(v) => !!v || "Required"],
      emailRules: [(v) => /.+@.+/.test(v) || "Invalid Email address"],
    };
  },
  created() {
    const currentURL = window.location.origin;
    this.agent_code = currentURL.split("://")[1].split(".")[0];
    if (
      currentURL
        .split("://")[1]
        .split(".")[0]
        .match(/localhost/)
    ) {
      this.agent_code = "trial";
    }
    // console.log(this.agent_code);
  },
  methods: {
    sendOTP: function () {
      if (!this.$refs.form.validate()) {
        return false;
      }
      this.overlay = true;
      this.axios
        .post("/api/send_otp", {
          email: this.email,
        })
        .then((response) => {
          //   console.log(response.data);
          if (response.data) {
            this.$router.push("/change_password/" + this.email);
          } else {
            const payload = {
              title: "Invalid user...",
              msg: "The email account provided is not existing in the database...",
            };
            this.$store.commit("showAlert", payload);
            this.$router.push("/login");
          }
        });
    },
  },
};
</script>
