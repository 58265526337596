<template>
  <div>
    <v-card flat>
      <v-container>
        <v-toolbar flat dark class="indigo lighten-3">
          <v-toolbar-title>API Token</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-btn class="red lighten-3" dark @click="generateToken">
            <v-icon>mdi-key-plus</v-icon>
            (re-)generate API Token
          </v-btn>
          <v-simple-table>
            <template
              v-slot:default
            >
              <thead>
                <tr>
                  <th>API Token</th>
                  <th>Active key age</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><p>{{token}}</p>
                    <div v-if="token">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon 
                            v-on="on"
                            @click="copyToClipboard"
                          >mdi-content-copy</v-icon>
                        </template>
                        <span>
                          Copy to Clipboard
                        </span>
                      </v-tooltip>                      
                    </div>
                  </td>
                  <td>{{activeKeyAge}} days ago</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      token: null,
      dateFrom: null,
      today: null,
      activeKeyAge: null,
    }
  },
  mounted() {

    this.axios.post(
      '/api/get_user',
      {
        user_id: this.$store.state.user_id
      }
    ).then(response => {
      // console.log(response.data);
      this.calculateActiveKeyAge(response.data[0].token_created_at);
      this.token = response.data[0].access_token;
    }).catch(error => {
      console.log(error);
    });
  },
  methods: {
    calculateActiveKeyAge: function(token_created_at) {
      if(!token_created_at) {
        this.activeKeyAge = 0;
      } else {
        this.dateFrom = moment(token_created_at);
        this.today = moment();
        this.activeKeyAge = this.today.diff(this.dateFrom, 'days');
      }
    },
    generateToken: function() {
      if(!confirm('Is it ok to (re-)generate your API Token?')) {
        return false;
      }
      this.axios.post(
        '/api/generate_api_token',
        {
          user_id: this.$store.state.user_id
        }
      )
        .then(response => {
          this.token = response.data.access_token;
          this.calculateActiveKeyAge(response.data.token_created_at);
        }).catch(error => {
          console.log(error);
        });
    },
    copyToClipboard: function() {
      if(navigator.clipboard.writeText(this.token)) {
        const payload = {
          title: "Copied!!",
          msg: "API Token copied to Clipboard!",
        };
        this.$store.commit("showAlert", payload);        
      }
    }
  },
}
</script>