<template>
    <div>
        <v-row>
            <v-col>
                <card1/>
            </v-col>
            <v-col>
                <card2/>
            </v-col>
            <v-col>
                <card3/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <card1/>
            </v-col>
            <v-col>
                <card2/>
            </v-col>
            <v-col>
                <card3/>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import Card1 from '../modules/DashboardCard1.vue';
import Card2 from '../modules/DashboardCard2.vue';
import Card3 from '../modules/DashboardCard3.vue';

export default {
    components: {
        'card1': Card1,
        'card2': Card2,
        'card3': Card3,
    }
}
</script>
