<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card flat>
      <v-container>
        <v-toolbar flat dark class="indigo lighten-3">
          <v-toolbar-title>Tickets</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" dark @click="generateCSV" v-if="$store.state.role === 'admin'">mdi-file-delimited</v-icon>
                  </template>
                  <span>Generate CSV</span>
                </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            class="row-pointer"
            :headers="ticketHeaders"
            :items="ticketItems"
            :loading="loading"
            :search="search"
            fixed-header
            height="50vh"
            hide-default-footer
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <template v-slot:top>
              <v-tabs class="mb-2" dark v-model="stage">
                <v-tab>all</v-tab>
                <v-tab>verifying document(s)</v-tab>
                <!-- <v-tab>for invoicing</v-tab>
                <v-tab>for payment</v-tab> -->
                <v-tab>finished</v-tab>
              </v-tabs>
              <v-text-field
                outlined
                dense
                v-model="search"
                placeholder="Serch by keyword..."
                prepend-icon="mdi-magnify"
              ></v-text-field>
            </template>
            <template v-slot:[`item.transaction_id_client`]="{ item }">
              {{item.transaction_id_client}}
              <v-icon
                v-if="$store.state.role === 'admin'"
                color="blue"
                @click="copyText(item)"
              >
                mdi-email
              </v-icon>
            </template>
            <template v-slot:[`item.containers`]="{ item }">
              <tr v-for="(c, i) in item.containers" :key="i">
                <td>{{ c }}</td>
              </tr>
            </template>
            <template v-slot:[`item.stage`]="{ item }">
              <div v-if="item.stage !== 'finished'">
                <v-btn
                  dark
                  class="orange lighten-3"
                  small
                  @click="openDialog(item.stage, item)"
                  >{{ item.stage }}</v-btn
                >
              </div>
              <div v-else-if="item.stage === 'finished'">
                finished
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon dark v-on="on"
                      ><v-icon color="blue" large @click="downloadCRO(item)"
                        >mdi-file-download</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>Download CRO</span>
                </v-tooltip> -->
              </div>
            </template>
            <template v-slot:[`item.progress`]="{ item }">
              <v-progress-linear
                v-show="!$vuetify.breakpoint.xs"
                height="25"
                dark
                :value="getProgressValue(item.uploaded_documents)"
                color="green"
              >
                <strong
                  >{{ getProgressValue(item.uploaded_documents) }}%</strong
                >
              </v-progress-linear>
              <strong v-show="$vuetify.breakpoint.xs"
                >{{ getProgressValue(item.uploaded_documents) }}%</strong
              >
              <v-badge
                color="red"
                :content="notYetStarted(item.uploaded_documents)"
                overlap
                bordered
                v-if="notYetStarted(item.uploaded_documents) != 0"
              >
                <v-chip color="red" dark x-small>not yet started</v-chip>
              </v-badge>
              <v-badge
                color="red"
                :content="pending(item.uploaded_documents)"
                overlap
                bordered
                v-if="pending(item.uploaded_documents) != 0"
              >
                <v-chip color="yellow" dark x-small>pending</v-chip>
              </v-badge>
              <v-badge
                color="red"
                :content="ok(item.uploaded_documents)"
                overlap
                bordered
                v-if="ok(item.uploaded_documents) != 0"
              >
                <v-chip color="green" dark x-small>ok</v-chip>
              </v-badge>
            </template>
            <template v-slot:[`item.original_documents`]="{ item }">
              <div v-if="item.stage !== 'verifying document(s)'">
                <v-badge
                  color="green"
                  bordered
                  overlap
                >
                  <template v-slot:badge>
                    {{ item.uploaded_documents.length }}
                  </template>
                    <v-icon
                      large
                      color="green"
                      @click="openUploadedDocumentsDialog(item)"
                    >
                      mdi-file-document
                    </v-icon>
                  </v-badge>
                </div>
                <div v-else>
                  -
                </div>
            </template>
            <template v-slot:[`item.uploaded_documents`]="{ item }">
              <div
                v-if="numberOfSealedDocuments(item.uploaded_documents) !== 0"
              >
                <v-badge bordered overlap>
                  <template v-slot:badge>{{
                    numberOfSealedDocuments(item.uploaded_documents)
                  }}</template>

                  <v-icon large color="blue" @click="openSealedDocumentsDialog(item)">mdi-file-document</v-icon>
                </v-badge>
              </div>
              <div v-else>-</div>
            </template>
            <template v-slot:[`item.cro_document`]="{ item }">
              <!-- <p v-if="item.cro_document">{{ item.cro_document.original_name}}</p> -->
              <v-tooltip bottom v-if="item.cro_document">
                <template v-slot:activator="{ on }">
                  <v-icon large color="blue" v-on="on" @click="downloadCRO2(item)">mdi-file-document</v-icon>
                </template>
                <span>Download CRO</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!item.cro_document && $store.state.role === 'admin' && item.stage === 'finished'">
                <template v-slot:activator="{ on }">
                  <v-icon small color="red" @click="openCROUploadingDialog(item)" v-on="on">mdi-upload</v-icon>
                </template>
                <span>Upload CRO</span>
              </v-tooltip>
              <v-tooltip bottom v-if="$store.state.super_user === 1 && item.stage === 'finished'">
                <template v-slot:activator="{ on }">
                  <v-icon small color="red" @click="deleteCRO(item)" v-on="on">mdi-delete</v-icon>
                </template>
                <span>Delete CRO</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.issued_at`]="{ item }">
              {{ item.issued_at | formatDate }}
            </template>
            <template v-slot:[`item.document_verification_finished_at`]="{ item }">
              {{ item.document_verification_finished_at | formatDate }}
            </template>
            <template v-slot:[`item.pullout_schedule_dates`]="{ item }">
              {{ item.pullout_schedule_dates | formatDate2 }}
            </template>
            <!-- <template v-slot:[`item.invoiced_at`]="{ item }">
              {{ item.invoiced_at | formatDate }}
            </template>
            <template v-slot:[`item.paid_at`]="{ item }">
              {{ item.paid_at | formatDate }}
            </template>                         -->
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="pageCount"
              total-visible="10"
            ></v-pagination>
            <v-text-field
              :value="itemsPerPage"
              label="Items per page"
              type="number"
              min="-1"
              max="15"
              @input="itemsPerPage = parseInt($event, 10)"
            ></v-text-field>
          </div>
        </v-card-text>
      </v-container>
    </v-card>

    <!--DocumentDialog start-->
    <v-dialog v-model="documentDialog" width="70%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card flat>
        <v-container>
          <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title
              >Documents</v-toolbar-title
            >
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="documentHeaders"
              :items="documentItems"
              :loading="loading"
            >
              <template v-slot:top>
                <v-simple-table>
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            Bl Number
                        </th>
                        <th class="text-left">
                            Transaction ID
                        </th>
                        <th class="text-left">
                            Container Number(s)
                        </th>
                        <th class="text-left">
                            PullOutScheduleDate
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>{{ bl_number }}</td>
                        <td>{{ transactionId }}</td>
                        <td>{{ container_numbers.join(', ') }}</td>
                        <td>{{ pullout_schedule_date }}</td>

                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                <v-divider></v-divider>
                <p>
                  <v-icon large color="red">mdi-stop</v-icon> = not yet started
                  <v-icon large color="yellow">mdi-pause</v-icon> = pending
                  <v-icon large color="green">mdi-check-bold</v-icon> = ok
                </p>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-icon v-if="item.status === 'pending'" large color="yellow"
                  >mdi-pause</v-icon
                >
                <v-icon v-else-if="item.status === 'ok'" large color="green"
                  >mdi-check-bold</v-icon
                >
                <v-icon
                  v-else-if="item.status === 'not yet started'"
                  large
                  color="red"
                  >mdi-stop</v-icon
                >
              </template>
              <template v-slot:[`item.sealed_document`]="{ item }">
                <div v-if="!item.sealed_document">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        color="blue"
                        v-on="on"
                        @click="uploadSealedDocument(item)"
                        :disabled="$store.state.role === 'user'"
                        >mdi-upload</v-icon
                      >
                    </template>
                    <span>Upload Sealed Document</span>
                  </v-tooltip>
                </div>
                <div v-else-if="item.sealed_document">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        color="blue"
                        @click="downloadSealedDocument(item.transaction_id + '/sealed_documents/', item.transaction_id + '-' + item.document_id + '.pdf')"
                        v-on="on"
                        >mdi-file-download</v-icon
                      >
                    </template>
                    <span>Download Sealed Document</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        color="red"
                        v-on="on"
                        @click="deleteSealedDocument(item)"
                        :disabled="$store.state.role === 'user'"
                        >mdi-delete</v-icon
                      >
                    </template>
                    <span>Delete Sealed Document</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | formatDate }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="editDocument(item)"
                      color="orange"
                      v-show="$store.state.role === 'admin'"
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <span>Verify Uploaded Document</span>
                </v-tooltip>
                <!-- <v-icon
                  @click="deleteDocument(item)"
                  color="red"
                  v-show="$store.state.role === 'admin'"
                  >mdi-delete</v-icon
                > -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      @click="downloadDocument(item)"
                      v-on="on"
                      color="green"
                      >mdi-download</v-icon
                    >
                  </template>
                  <span>Download Uploaded Document</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <!--DocumentDialog finish-->

    <!--DocumentVerifyingDialog start-->
    <v-dialog width="60%" v-model="openDocumentVerifyingDialog">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-container>
          <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title>Verify Document(s) --- BL Number: {{ bl_number }}</v-toolbar-title>
          </v-toolbar>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1"
                  >Document Title: {{ title }} ({{
                    description
                  }})</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  File Name: {{ name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  <v-row>
                    <v-col xs="12" sm="12" md="2" lg="2">
                      <p>
                        <v-icon large color="red">mdi-stop</v-icon> = not yet
                        started
                        <v-icon large color="yellow">mdi-pause</v-icon> =
                        pending
                        <v-icon large color="green">mdi-check-bold</v-icon> = ok
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col xs="12" sm="12" md="2" lg="2">
                      Status:
                      <v-icon v-if="status === 'pending'" large color="yellow"
                        >mdi-pause</v-icon
                      >
                      <v-icon v-if="status === 'ok'" large color="green"
                        >mdi-check-bold</v-icon
                      >
                      <v-icon
                        v-if="status === 'not yet started'"
                        large
                        color="red"
                        >mdi-stop</v-icon
                      >
                    </v-col>
                    <v-col xs="12" sm="12" md="4" lg="4">
                      <v-select
                        label="Change To"
                        :items="statuses"
                        v-model="status"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col xs="12" sm="12" md="3" lg="3">
                      <v-textarea
                        v-model="pendingRemark"
                        outlined
                        label="Remark"
                        rows="2"
                        cols="10"
                        v-if="status === 'pending'"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col xs="12" sm="12" md="12" lg="12">
                      <v-btn
                        dark
                        block
                        color="red lighten-3"
                        @click="updateDocumentStatus()"
                        :disabled="!documentStatusChanged"
                      >
                        <v-icon>mdi-content-save</v-icon>Save Change
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-container>
      </v-card>
    </v-dialog>
    <!--DocumentVerifyingDialog finish-->

    <!--SealedDocumentUploadingDialog start-->
    <v-dialog width="60%" v-model="sealedDocumentUploadingDialog">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card flat>
        <v-container>
          <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title>Upload Sealed Document</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-file-input
              placeholder="Upload File..."
              v-model="userFile"
              dense
              outlined
              chips
              accept=".pdf"
              @change="addFile()"
            ></v-file-input>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <!--SealedDocumentUploadingDialog finish-->

    <!--ForInvoicingDialog start-->
    <v-dialog width="70%" v-model="forInvoicingDialog">
      <v-card flat>
        <v-container>
          <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title>For Invoicing --- BL Number: {{ bl_number }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-overlay :value="overlay">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-form ref="form">
              <v-card-text>
                <v-row justify="center" align="center">
                  <v-col cols="12" lg="4" md="4">
                    <v-select
                      v-model="currency"
                      label="Currency"
                      dense
                      outlined
                      required
                      :items="['$', '₱']"
                      :rules="rules"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" lg="4" md="4">
                    <v-text-field
                      v-model="paymentAmount"
                      label="Payment Amount"
                      dense
                      outlined
                      required
                      :rules="rules"
                      type="number"
                      @keyup="validateNumbers()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4" md="4">
                    <v-select
                      label="Return Empty Container To"
                      v-model="returnEmptyContainerTo"
                      :items="returnEmptyContainerToOptions"
                      item-text="return_empty_container_to"
                      item-value="return_empty_container_to"
                      dense
                      outlined
                      required
                      :rules="rules"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="4" md="4">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <br />
                        <v-text-field
                          v-model="croValidUntil"
                          label="CRO Valid Until"
                          prepend-inner-icon="event"
                          dense
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          required
                          :rules="rules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="croValidUntil"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" lg="4" md="4">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <br />
                        <v-text-field
                          v-model="dischargeDate"
                          label="Discharge Date"
                          prepend-inner-icon="event"
                          dense
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          required
                          :rules="rules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dischargeDate"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" lg="4" md="4">
                    <v-menu
                      v-model="menu3"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <br />
                        <v-text-field
                          v-model="lastFreeTimeDate"
                          label="Last FreeTime Date"
                          prepend-inner-icon="event"
                          dense
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          required
                          :rules="rules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="lastFreeTimeDate"
                        @input="menu3 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <!-- <v-row>
                    <v-col cols="12" lg="4" md="4">
                        <v-file-input
                            dense
                            outlined
                            chips
                            v-model="cro"
                            placeholder="CRO"
                            accept=".pdf"
                            :rules="rules"
                        ></v-file-input>
                    </v-col>
                </v-row> -->
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      block
                      class="red lighten-3"
                      dark
                      @click="updateTransactionForPayment()"
                    >
                      <v-icon>mdi-update</v-icon>update
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <!--ForInvoicingDialog finish-->

    <!--SealedDocumentsDialog start-->
    <v-dialog width="70%" v-model="sealedDocumentsDialog">
      <v-card flat>
        <v-container>
          <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title>Sealed Documents --- BL Number: {{ bl_number }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="sealedDocumentHeaders"
              :items="sealedDocumentItems"
              :loading="loading"
            >
              <template v-slot:top>
                <v-toolbar-title>BlNumber: {{ bl_number }}</v-toolbar-title>
              </template>
              <template v-slot:[`item.download`]="{ item }">
                <v-icon dark color="green" @click="downloadSealedDocument(item.path + '/', item.name)">mdi-file-download</v-icon>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | formatDate }}
              </template>
              <template v-slot:[`item.delete`]="{ item }" v-if="$store.state.super_user === 1 || $store.state.email === 'mlaus@cargoexchangesystems.com.ph' || $store.state.email === 'instructor20190401@gmail.com'">
                <v-icon dark color="red" @click="deleteSealedDocument2(item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <!--SealedDocumentsDialog finish-->

    <!--uploadedDocumentsDialog start-->
    <v-dialog width="70%" v-model="uploadedDocumentsDialog">
      <v-card flat>
        <v-container>
          <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title>Uploaded Documents --- BL Number: {{ bl_number }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="uploadedDocumentHeaders"
              :items="uploadedDocumentItems"
              :loading="loading"
            >
              <template v-slot:top>
                <v-toolbar-title>BlNumber: {{ bl_number }}</v-toolbar-title>
              </template>
              <template v-slot:[`item.download`]="{ item }">
                <v-icon dark color="green" @click="downloadUploadedDocument(item.transaction_id + '/', item.name)">mdi-file-download</v-icon>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | formatDate }}
              </template>
              <template v-slot:[`item.delete`]="{ item }" v-if="$store.state.super_user === 1 || $store.state.email === 'mlaus@cargoexchangesystems.com.ph' || $store.state.email === 'instructor20190401@gmail.com'">
                <v-icon color="red" @click="deleteUploadedDocument(item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <!--uploadedDocumentsDialog finish-->

    <!--croUploadingDialog start-->
    <v-dialog v-model="croUploadingDialog">
      <v-card flat>
        <v-container>
          <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title>Upload CRO document --- BL Number: {{ bl_number }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-file-input
              placeholder="Upload CRO document..."
              v-model="croFiles"
              dense
              outlined
              chips
              multiple
              accept=".pdf"
              @change="uploadTempCRO()"
            ></v-file-input>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="file, i in croTempFiles" :key="i">
                  <td>{{ file.name }}</td>
                  <td>
                    <v-icon color="red" @click="deleteCroTempFile(i)">mdi-delete</v-icon>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="red lighten-2" dark @click="mergeTempCRO">
              <v-icon>mdi-upload</v-icon>upload
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <!--croUploadingDialog finish -->
  </div>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      overlay: false,
      loading: false,
      search: null,
      stage: 0,
      pageCount: 0,
      page: 1,
      itemsPerPage: 30,
      documentDialog: false,
      transactionId: null,
      croUploadingDialog: false,
      ticketHeaders: [
        // {
        //   text: "TransactionId",
        //   value: "transaction_id",
        //   sortable: true,
        // },
        {
          text: "TransactionId(client)",
          value: "transaction_id_client",
          sortable: true,
        },
        {
          text: "Container#",
          value: "containers",
          sortable: true,
        },
        {
          text: "BlNumber",
          value: "bl_number",
          sortable: true,
        },
        {
          text: "Stage",
          value: "stage",
          sortable: true,
        },
        {
          text: "Progress",
          value: "progress",
          sortable: true,
        },
        {
          text: "UploadedDocuments",
          value: "original_documents",
          sortable: true,
        },
        {
          text: "SealedDocs",
          value: "uploaded_documents",
          sortable: true,
        },
        {
          text: "CRO",
          value: "cro_document",
          sortable: true,
        },
        {
          text: "Category",
          value: "document_category.name",
          sortable: true,
        },
        {
          text: "Issued",
          value: "issued_at",
          sortable: true,
        },
        {
          text: "VerificationFinished",
          value: "document_verification_finished_at",
          sortable: true,
        },
        {
          text: "PulloutScheduleDate",
          value: "pullout_schedule_dates",
          sortable: true,
        },
        // {
        //   text: "Invoiced",
        //   value: "invoiced_at",
        //   sortable: true,
        // },
        // {
        //   text: "Paid",
        //   value: "paid_at",
        //   sortable: true,
        // },
      ],
      ticketItems: [],
      documentHeaders: [
        {
          text: "Name",
          value: "original_name",
          sortable: true,
        },
        {
          text: "Category",
          value: "document.title",
          sortable: true,
        },
        {
          text: "Description",
          value: "document.description",
          sortable: true,
        },
        {
          text: "Status",
          value: "status",
          sortable: true,
        },
        {
          text: "Remark",
          value: "remark",
          sortable: true,
        },
        {
          text: "SealedDoc",
          value: "sealed_document",
          sortable: true,
        },
        {
          text: "Uploaded",
          value: "created_at",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      documentItems: [],
      openDocumentVerifyingDialog: false,
      uploaded_document_id: null,
      document_id: null,
      name: null,
      title: null,
      description: null,
      status: null,
      documentStatusChanged: false,
      statuses: ["not yet started", "pending", "ok"],
      pendingRemark: null,

      forInvoicingDialog: false,
      rules: [(v) => !!v || "Required!"],
      currency: null,
      paymentAmount: null,
      menu: false,
      menu2: false,
      menu3: false,
      croValidUntil: null,
      dischargeDate: null,
      lastFreeTimeDate: null,
      returnEmptyContainerTo: null,
      returnEmptyContainerToOptions: [],
      cro: null,
      croFiles: [],
      croTempFiles: [],
      sealedDocumentUploadingDialog: false,
      userFile: null,
      formData: new FormData(),

      sealedDocumentItems: [],
      sealedDocumentsDialog: false,
      sealedDocumentHeaders: [
        {
          text: "Name",
          value: "original_name",
          sortable: true,
        },
        {
          text: "Title",
          value: "document.title",
          sortable: true,
        },
        {
          text: "Description",
          value: "document.description",
          sortable: true,
        },
        {
          text: "Download",
          value: "download",
          sortable: true,
        },
        {
          text: "Delete",
          value: "delete",
          sortable: true,
        },
        {
          text: "CreatedAt",
          value: "created_at",
          sortable: true,
        },
      ],
      uploadedDocumentItems: [],
      uploadedDocumentsDialog: false,
      uploadedDocumentHeaders: [
        {
          text: "Name",
          value: "original_name",
          sortable: true,
        },
        {
          text: "Title",
          value: "document.title",
          sortable: true,
        },
        {
          text: "Description",
          value: "document.description",
          sortable: true,
        },
        {
          text: "Download",
          value: "download",
          sortable: true,
        },
        {
          text: "Delete",
          value: "delete",
          sortable: true,
        },
        {
          text: "CreatedAt",
          value: "created_at",
          sortable: true,
        },
      ],
      bl_number: null,
      transaction_id: null,
      container_numbers: [],
      pullout_schedule_date: null,
    };
  },
  mounted() {
    // console.log(this.$store.state.access_token);
    this.getTransactionsWithIssuedAt();
  },
  methods: {
    generateCSV: function() {
      this.downloading = true;
      this.overlay = true;
      this.axios.post(
        '/api/generate_csv',
          {
            responseType: "blob",
          }
      )
        .then(response => {
            // console.log(response.data)
          this.downloading = false;
          this.overlay = false;
          const fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "text/csv" })
          );
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "transaction.csv");
          fileLink.target = "_blank";
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
        }).catch(error => {
          console.log(error);
        });
    },
    copyText(item) {
      if( navigator.clipboard.writeText(item.user.email) ) {
        const payload = {
          title: "Email address copied!",
          msg: item.user.email,
        };
        this.$store.commit("showAlert", payload);
      }
    },
    validateNumbers: function () {
      // this.paymentAmount = this.paymentAmount.replace(/\D/g, "") * 1;
      this.paymentAmount = this.paymentAmount * 1;
    },
    getTransactionsWithIssuedAt: function () {
      this.overlay = true;
      this.loading = true;
      axios
        .post("/api/get_transactions_with_issued_at", {
          process_kind: 'shipping_line',
          role: this.$store.state.role,
          user_id: this.$store.state.user_id,
          stage: this.stage,
        })
        .then((response) => {
          this.ticketItems = [];
          for(let r of response.data) {
            let containers = [];
            let pullout_schedule_dates = [];
            for( let c of r.containers) {
              containers.push(c.container_number);
              pullout_schedule_dates.push(c.pullout_schedule_date);
            }
            r.containers = containers;
            r.pullout_schedule_dates = pullout_schedule_dates;
            this.ticketItems.push(r);
          }

          this.overlay = false;
          this.loading = false;

          // console.log(this.ticketItems);

          // this.ticketItems = response.data;
          // console.log(this.ticketItems);

          // if (this.$route.query.transaction_id) {
          //   this.search = this.$route.query.transaction_id;
          // }
          if (this.$route.params.transaction_id) {
            this.search = this.$route.params.transaction_id;
          }
        })
        .catch((error) => {
          this.overlay = false;
          this.loading = false;
          console.log(error);
        });
    },
    openDialog: function (stage, item) {
      this.transactionId = item.transaction_id;
      this.bl_number = item.bl_number;
      this.pullout_schedule_date = moment(item.containers[0].pullout_schedule_date).format("MM-DD-YYYY");

      this.container_numbers = [];
      if(item.containers.length > 0) {
          for( const c of item.containers ) {
              this.container_numbers.push(c);
          }
      }

      if (stage === "verifying document(s)") {
        this.documentDialog = true;
        this.getUploadedDocuments(this.transactionId);
      } else if (
        stage === "for invoicing" &&
        this.$store.state.role === "admin"
      ) {
        axios
          .post("/api/get_container_yards")
          .then((response) => {
            if (response.data.length === 0) {
              alert("Register container yard(s) first...");
              location.href = "/admin/container_yards";
              return false;
            }
            this.returnEmptyContainerToOptions = response.data;
            this.forInvoicingDialog = true;
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (stage === "for payment" && this.$store.state.role === "user") {
        this.$router.push({
          path: "/payment",
          query: {
            transaction_id: this.transactionId,
            amount: item.payment_amount,
          },
        });
      } else {
        alert("You have no access right to enter the area.");
      }
    },

    getUploadedDocuments: function (transaction_id) {
      axios
        .post("/api/get_uploaded_documents", {
          transaction_id: transaction_id,
          agent_code: this.$store.state.agent_code,
          bl_number: this.bl_number
        })
        .then((response) => {
          // console.log(response.data);
          this.documentItems = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editDocument: function (item) {
      axios
        .post("/api/check_if_with_emailaddress", {
          document_id: item.document_id,
        })
        .then((response) => {
          if (!response.data) {
            const payload = {
              title: "Set emailaddress to the document master!",
              msg: "Before verifying the document you need to set an emailaddress to the document master...",
            };
            this.$store.commit("showAlert", payload);
            this.documentDialog = false;
            this.$router.push("/admin/documents");
            return false;
          } else {
            this.openDocumentVerifyingDialog = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      // console.log(item);
      this.document_id = item.document_id;
      this.uploaded_document_id = item.id;
      this.name = item.original_name;
      this.title = item.document.title;
      this.description = item.document.description;
      this.pendingRemark = item.remark;
      this.status = item.status;
    },
    deleteDocument: function (item) {
      if (!confirm("Is it ok to delete this document?")) {
        return false;
      }
      // console.log(item);
    },
    downloadDocument: function (item) {
      this.overlay = true;
      this.downloading = true;
      axios
        .post(
          "/api/download_document",
          {
            transaction_id: item.transaction_id,
            name: item.name,
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          this.downloading = false;
          this.overlay = false;
          const fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.target = "_blank";
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
        });
    },
    updateDocumentStatus: function () {
      if (this.status === "pending") {
        if (!this.pendingRemark) {
          this.documentStatusChanged = false;
          alert("you need to put something in pendingRemark");
          return false;
        } else {
          this.documentStatusChanged = true;
        }
      }
      this.overlay = true;
      this.loading = true;
      axios
        .post("/api/update_document_status", {
          process_kind: 'shipping_line',
          transaction_id: this.transactionId,
          id: this.uploaded_document_id,
          document_id: this.document_id,
          title: this.title,
          description: this.description,
          status: this.status,
          agent_code: this.$store.state.agent_code,
          email: this.$store.state.email,
          remark: this.pendingRemark,
        })
        .then((response) => {
          // console.log(response.data);
          this.getUploadedDocuments(this.transactionId);
          this.checkAndUpdateStage(this.transactionId);
          this.getTransactionsWithIssuedAt();
          this.openDocumentVerifyingDialog = false;
          this.overlay = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.overlay = false;
          this.loading = false;
        });
    },
    checkAndUpdateStage: function(transaction_id) {
      this.axios.post('/api/check_and_update_stage', {
          transaction_id: transaction_id,
          agent_code: this.$store.state.agent_code,
          bl_number: this.bl_number
      }).then(response => {
        console.log(response.data);
      }).catch(error => {
        console.log(error);
      });
    },
    getProgressValue: function (documents) {

      const totalNumber = documents.length;

      const finishedDocuments = documents.filter(function (doc) {
        return doc.verified_at !== null && doc.status === 'ok';
      });

      return !totalNumber
        ? "-"
        : Math.round((finishedDocuments.length / totalNumber) * 10000) / 100;
    },
    updateTransactionForPayment: function () {
      if (!this.$refs.form.validate()) {
        return false;
      }

    //   this.formData = new FormData();
    //   this.formData.append('currency', this.currency);
    //   this.formData.append('email', this.$store.state.email);
    //   this.formData.append('agent_code', this.$store.state.agent_code);
    //   this.formData.append('payment_amount', this.paymentAmount);
    //   this.formData.append('transaction_id', this.transactionId);
    //   this.formData.append('cro_valid_until',this.croValidUntil);
    //   this.formData.append('discharge_date', this.dischargeDate);
    //   this.formData.append('last_free_time_date', this.lastFreeTimeDate);
    //   this.formData.append('return_empty_container_to', this.returnEmptyContainerTo);
    //   this.formData.append('cro', this.cro);

      axios
        .post("/api/update_transaction_for_payment",
            {
                currency: this.currency,
                email: this.$store.state.email,
                agent_code: this.$store.state.agent_code,
                payment_amount: this.paymentAmount,
                transaction_id: this.transactionId,
                cro_valid_until: this.croValidUntil,
                discharge_date: this.dischargeDate,
                last_free_time_date: this.lastFreeTimeDate,
                return_empty_container_to: this.returnEmptyContainerTo,
            },
            // this.formData,
            // {
            //     headers: {
            //         "Content-Type": "multipart/form-data",
            //     },
            // }
        )
        .then((response) => {
          // console.log(response.data);
          this.forInvoicingDialog = false;
          this.currency = null;
          this.paymentAmount = null;
          this.transactionId = null;
          this.croValidUntil = null;
          this.dischargeDate = null;
          this.lastFreeTimeDate = null;
          this.returnEmptyContainerTo = null;
        //   this.cro = null;
        //   this.formData = null;
          this.getTransactionsWithIssuedAt();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    uploadSealedDocument: function (item) {
      // console.log(item);
      this.sealedDocumentUploadingDialog = true;
      this.document_id = item.document_id;
    },
    deleteSealedDocument: function (item) {
      if (!confirm("Is it ok to delete this document?")) {
        return false;
      }
      axios
        .post("/api/delete_sealed_document", {
          transaction_id: item.transaction_id,
          document_id: item.document_id,
          sealed_document_id: item.id,
        })
        .then((response) => {
          this.getTransactionsWithIssuedAt();
          this.getUploadedDocuments(this.transactionId);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteSealedDocument2: function(item) {
      if (!confirm("Is it ok to delete this document?")) {
        return false;
      }

      axios
        .post("/api/delete_sealed_document", {
          transaction_id: item.path.split('/')[0],
          document_id: item.document_id,
          sealed_document_id: item.id,
        })
        .then((response) => {
          this.getTransactionsWithIssuedAt();
          this.sealedDocumentItems.splice(this.sealedDocumentItems.indexOf(item), 1);
        })
        .catch((error) => {
          console.log(error);
        });

    },
    downloadSealedDocument: function (path, name) {
      this.overlay = true;
      this.downloading = true;
      axios
        .post(
          "/api/download_sealed_document",
          {
            path: path,
            name: name,
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          this.downloading = false;
          this.overlay = false;
          const fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.target = "_blank";
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
        });
    },
    downloadUploadedDocument: function (transaction_id, name) {
      this.overlay = true;
      this.downloading = true;
      axios
        .post(
          "/api/download_document",
          {
            transaction_id: transaction_id,
            name: name,
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          this.downloading = false;
          this.overlay = false;
          const fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.target = "_blank";
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
        });
    },
    numberOfSealedDocuments: function (data) {
      const docs = data.filter(function (val) {
        return val.sealed_document_id !== null;
      });
      return docs.length;
    },
    addFile: function () {
      if (!this.userFile) {
        return false;
      }

      this.overlay = true;
      this.formData = new FormData();
      this.formData.append("file0", this.userFile);
      this.formData.append("transaction_id", this.transactionId);
      this.formData.append("document_id", this.document_id);

      this.axios
        .post(
          "/api/upload_sealed_document",
          this.formData,
          {
            onUploadProgress: this.onUpload,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          if (response.status === 200) {
            this.overlay = false;
            this.userFile = null;
            this.sealedDocumentUploadingDialog = false;
            this.getTransactionsWithIssuedAt();
            this.getUploadedDocuments(this.transactionId);
            this.formData = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // downloadCRO: function (item) {
    // //   console.log(item);
    //   this.downloading = true;
    //   this.overlay = true;
    //   this.axios
    //     .post(
    //       "/api/download_cro",
    //       {
    //         agent_code: this.$store.state.agent_code,
    //         transaction_id: item.transaction_id,
    //         bl_number: item.bl_number,
    //         container_numbers: JSON.stringify(item.containers),
    //         finished_at: item.paid_at,
    //         discharge_date: item.discharge_date,
    //         last_freetime_date: item.last_free_time_date,
    //         return_empty_container_to: item.return_empty_container_to,
    //         cro_valid_until: item.cro_valid_until,
    //         // shipping_line: item.shipping_line,
    //       },
    //       {
    //         responseType: "blob",
    //       }
    //     )
    //     .then((response) => {
    //       // const fileURL = window.URL.createObjectURL(
    //       //     new Blob([response.data], { type: "application/pdf" })
    //       // );
    //       // this.$refs.pdfView.src = fileURL;
    //       // console.log(response.data);

    //       this.downloading = false;
    //       this.overlay = false;
    //       // return false;
    //       const fileURL = window.URL.createObjectURL(
    //         new Blob([response.data], { type: "application/pdf" })
    //       );
    //       const fileLink = document.createElement("a");
    //       fileLink.href = fileURL;
    //       //   fileLink.setAttribute(
    //       //     "download",
    //       //     this.bl_number + "_" + item.number + ".pdf"
    //       //   );
    //       fileLink.target = "_blank";
    //       document.body.appendChild(fileLink);
    //       fileLink.click();
    //       document.body.removeChild(fileLink);
    //     });
    // },
    notYetStarted: function (items) {
      const results = items.filter(function (value) {
        return value.status === "not yet started";
      });
      return results.length;
    },
    pending: function (items) {
      const results = items.filter(function (value) {
        return value.status === "pending";
      });
      return results.length;
    },
    ok: function (items) {
      const results = items.filter(function (value) {
        return value.status === "ok";
      });
      return results.length;
    },
    openSealedDocumentsDialog: function(item) {
      this.overlay = true;
      this.loading = true;
      this.sealedDocumentsDialog = true;
      this.transaction_id = item.transaction_id;
      this.bl_number = item.bl_number;
      this.axios.post('/api/get_sealed_documents',
      {
        transaction_id: item.transaction_id,
      }).then(response => {
        // console.log(response.data);
        this.sealedDocumentItems = response.data;
        this.overlay = false;
        this.loading = false;
      }).catch(error => {
        console.log(error);
        this.overlay = false;
        this.loading = false;
      })
    },
    openUploadedDocumentsDialog: function(item) {
      this.overlay = true;
      this.loading = true;
      this.uploadedDocumentsDialog = true;
      this.transaction_id = item.transaction_id;
      this.bl_number = item.bl_number;


      // this.uploadedDocumentItems = item.uploaded_documents;

      // console.log(item.uploaded_documents);
      axios
        .post("/api/get_uploaded_documents", {
          transaction_id: item.transaction_id,
          agent_code: this.$store.state.agent_code,
        })
        .then((response) => {
          // console.log(response.data);
          this.uploadedDocumentItems = response.data;
          this.overlay = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.overlay = false;
          this.loading = false;
        });


      // this.axios.post('/api/get_uploaded_documents_for_view_only',
      // {
      //   transaction_id: item.transaction_id,
      // }).then(response => {
      //   this.uploadedDocumentItems = response.data;
      //   this.overlay = false;
      //   this.loading = false;
      // }).catch(error => {
      //   console.log(error);
      //   this.overlay = false;
      //   this.loading = false;
      // })
    },
    openCROUploadingDialog: function(item) {
      this.croUploadingDialog = true;
      this.bl_number = item.bl_number;
      this.transaction_id = item.transaction_id;
      this.croTempFiles = [];
      this.croFiles = null;
      // console.dir(item)
    },
    uploadTempCRO: function() {

      if(!this.croFiles.length) {
        return false;
      }

      for ( let cro of this.croFiles ) {
        this.croTempFiles.push(cro);
      }
      // console.log(this.croTempFiles);
    },
    deleteCroTempFile: function(i) {
      this.croTempFiles.splice(i, 1);
    },
    mergeTempCRO: function() {
      this.formData = new FormData();

      let i = 0;

      for( let cro of this.croTempFiles ) {
        this.formData.append('file' + i, cro);
        i++;
      }

      this.formData.append('transaction_id', this.transaction_id);
      this.formData.append('bl_number', this.bl_number);
      this.formData.append('agent_code', this.$store.state.agent_code);
      this.formData.append('email', this.$store.state.email);

      this.overlay = true;
      this.axios.post(
        '/api/upload_cro',
        this.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      ).then(response => {
        if(response.status === 200) {
          this.overlay = false;
          this.croUploadingDialog = false;
          this.getTransactionsWithIssuedAt();
          // console.dir(response.data);
        }
      }).catch(error => {
        this.overlay = false;
        this.croUploadingDialog = false;
        console.dir(error);
      });
    },
    downloadCRO2: function(item) {
      // console.dir(item)
      this.overlay = true;
      this.downloading = true;
      axios
        .post(
          "/api/download_cro2",
          {
            path: item.cro_document.path,
            name: item.cro_document.name,
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          this.downloading = false;
          this.overlay = false;
          const fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.target = "_blank";
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
        }).catch(error => {
          this.downloading = false;
          this.overlay = false;
          console.dir(error);
        });
    },
    deleteCRO: function(item) {
      this.overlay = true;
      this.downloading = true;

      this.axios.post(
        '/api/delete_cro',
        {
          transaction_id: item.transaction_id,
        }
      ).then(response => {
        this.overlay = false;
        this.downloading = false;
        if(response.data === 'ok') {
          this.getTransactionsWithIssuedAt();
        }
      }).catch(error => {
        console.dir(error);
        this.overlay = false;
        this.downloading = false;
      });
    },
    deleteUploadedDocument: function(item) {
      if (!confirm("Is it ok to delete this document?")) {
        return false;
      }
      this.overlay = true;
      this.downloading = true;
      this.axios.post('/api/delete_uploaded_document',{
        transaction_id: item.transaction_id,
        document_id: item.document_id,
        name: item.name
      }).then(response => {
        this.overlay = false;
        this.downloading = false;
        this.uploadedDocumentItems.splice(this.uploadedDocumentItems.indexOf(item), 1);
        this.getTransactionsWithIssuedAt();
      }).catch(error => {
        this.overlay = false;
        this.downloading = false;
        console.log(error);
      });
    }
  },
  filters: {
    formatDate: function (date) {
      return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
      // return moment(date).format("MM-DD-YYYY HH:mm:ss");
    },
    formatDate2: function (dates) {
      let ds = [];
      for( let date of dates ) {
        ds.push(date !== null ? moment(date).format("MM-DD-YYYY") : '');
      }
      return ds.join(" | ");
    }
  },
  watch: {
    status: function (oldValue, newValue) {
      if (newValue) {
        this.documentStatusChanged = true;
      }
    },
    pendingRemark: function (newValue) {
      if (newValue) {
        this.documentStatusChanged = true;
      }
    },
    stage: function(newValue) {
      console.log(newValue);
      this.getTransactionsWithIssuedAt();
    }
  },
};
</script>
<style lang="css" scoped>
/* .row-pointer >>> tbody tr :hover {
  cursor: pointer;
} */
</style>
