<template>
  <div>
    <v-card>
      <v-container>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :search="search"
          fixed-header
          height="50vh"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:top>
            <v-toolbar flat color="indigo lighten-3" dark>
              <v-toolbar-title>BL Numbers</v-toolbar-title>
            </v-toolbar>
            <v-text-field
              v-model="search"
              label="search"
              outlined
              class="my-3"
              append-icon="mdi-magnify"
              dense
            ></v-text-field>
          </template>
          <template v-slot:[`item.bl_number`]="{ item }">
            <a @click="openDetail(item)">{{ item.bl_number }}</a>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            total-visible="10"
          ></v-pagination>
          <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div>
      </v-container>
    </v-card>

    <v-dialog v-model="dialog" width="80%">
      <v-card>
        <v-container fluid>
          <v-toolbar dark class="indigo lighten-1">{{ BlNumber }}</v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="detailHeaders"
              :items="detailItems"
              hide-default-footer
            ></v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark @click="dialog = !dialog">close</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      loading: false,
      dialog: false,
      BlNumber: null,
      search: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 30,
      documentDialog: false,
      headers: [
        {
          text: "BlNumber",
          value: "bl_number",
          sortable: true,
        },
        {
          text: "FileName",
          value: "filename",
          sortable: true,
        },
        {
          text: "updated_at",
          value: "updated_at",
          sortable: true,
        },
      ],
      items: [],
      detailHeaders: [
        {
          text: "ConsigneeName",
          value: "consignee_name",
          sortable: true,
        },
        {
          text: "ConsigneeAddress",
          value: "consignee_address",
          sortable: true,
        },
        {
          text: "ExporterName",
          value: "exporter_name",
          sortable: true,
        },
        {
          text: "ExporterAddress",
          value: "exporter_address",
          sortable: true,
        },
        {
          text: "NotifyPartyName",
          value: "notify_party_name",
          sortable: true,
        },
        {
          text: "NotifyPartyAddress",
          value: "notify_party_address",
          sortable: true,
        },
        {
          text: "ContainerNumbers",
          value: "container_numbers",
          sortable: true,
        },
        {
          text: "PlaceOfLoading",
          value: "place_of_loading",
          sortable: true,
        },
        {
          text: "PlaceOfUnloading",
          value: "place_of_unloading",
          sortable: true,
        },
        {
          text: "PackageTypeCode",
          value: "package_type_code",
          sortable: true,
        },
        {
          text: "NumberOfPackages",
          value: "number_of_packages",
          sortable: true,
        },
        {
          text: "ShippingMarks",
          value: "shipping_marks",
          sortable: true,
        },
        {
          text: "GoodsDescription",
          value: "goods_description",
          sortable: true,
        },
        {
          text: "NumberOfSeals",
          value: "number_of_seals",
          sortable: true,
        },
        {
          text: "RegistryNumber",
          value: "registry_number",
          sortable: true,
        },
        {
          text: "CustomsOfficeCode",
          value: "customs_office_code",
          sortable: true,
        },
        {
          text: "LineNumber",
          value: "line_number",
          sortable: true,
        },
        {
          text: "BolNature",
          value: "bol_nature",
          sortable: true,
        },
        {
          text: "TotalNumberOfContainers",
          value: "total_number_of_containers",
          sortable: true,
        },
        {
          text: "TotalGrossMassManifested",
          value: "total_gross_mass_manifested",
          sortable: true,
        },
        {
          text: "VolumeInCubicMeters",
          value: "volume_in_cubic_meters",
          sortable: true,
        },
        {
          text: "BolTypeCode",
          value: "bol_type_code",
          sortable: true,
        },
      ],
      detailItems: [],
      tables: [],
    };
  },
  created() {
    this.overlay = true;
    this.loading = true;
    // this.axios.defaults.headers.common["Authorization"] =
    //   "Bearer " + this.$store.state.access_token;
    this.axios
      .post("/api/get_bl_numbers", {
        agent_code: this.$store.state.agent_code,
      })
      .then((response) => {
        this.items = response.data;
        this.overlay = false;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.overlay = false;
        this.loading = false;
      });
    this.showTables();
  },
  methods: {
    showTables: function () {},
    openDetail: function (item) {
      this.BlNumber = item.bl_number;
      this.dialog = true;
      this.axios
        .post("/api/get_details", { bl_number: this.BlNumber })
        .then((response) => {
          //   console.log(response.data);
          this.detailItems = response.data;
        });
    },
  },
};
</script>
