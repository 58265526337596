<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <a href="/sign_up" v-show="signUpLink"
            >If you are a new user, sign up first</a
          >
          <v-card class="elevation-1">
            <v-toolbar color="black" dark flat>
              <v-toolbar-title>Login form</v-toolbar-title>
            </v-toolbar>
            <v-form ref="form">
              <v-card-text>
                <v-text-field
                  label="Login"
                  name="login"
                  prepend-icon="person"
                  type="text"
                  v-model="login"
                  :rules="emailRules"
                />

                <v-text-field
                  v-model="password"
                  label="Password"
                  name="password"
                  prepend-icon="lock"
                  type="password"
                  :rules="rules"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="black" dark @click="auth" :disabled="loginBtn"
                  >Login</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <a href="/forget" v-show="forgetLink">Forgot Password?</a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      overlay: false,
      login: null,
      loginBtn: true,
      signUpLink: false,
      forgetLink: false,
      password: null,
      subdomainSecondLayer: null,
      agent_code: null,
      rules: [(v) => !!v || "Required"],
      emailRules: [(v) => /.+@.+/.test(v) || "Invalid Email address"],
      email: null,
    };
  },
  created() {
    // console.log(this.$store.state.access_token);
    // Check agent_code...
    this.axios
      .post("/api/get_shipping_lines")
      .then((response) => {
        const currentURL = window.location.origin;

        this.agent_code = currentURL.split("://")[1].split(".")[0];
        this.subdomainSecondLayer =
          ! currentURL.split("://")[1].split(".")[1].match(/localhost|systembase/) ?
          currentURL.split("://")[1].split(".")[1] :
          null;

        // console.log(this.subdomainSecondLayer)

        const res = response.data.filter((value) => {
          return value.subdomain == this.agent_code;
        });

        if (!res.length) {
          const payload = {
            title: "Invalid URL",
            msg: "You are pointing invalid URL...",
          };
          this.$store.commit("showAlert", payload);
          return false;
        }
        this.loginBtn = false;
        this.signUpLink = true;
        this.forgetLink = true;
      })
      .catch((error) => {
        console.log(error);
      });

    this.$store.commit("loggedOut");
    this.$destroy();
  },
  methods: {
    auth: function () {
      if (!this.$refs.form.validate()) {
        return false;
      }
      this.overlay = true;
      this.axios
        .post("/api/login", {
          email: this.login,
          password: this.password,
        })
        .then((response) => {
          //   console.log(response.status);
          if (response.status === 200) {
            if (!response.data.user) {
              const payload = {
                title: "Invalid user...",
                msg: "You provided invalid user information...",
              };
              this.$store.commit("showAlert", payload);
              this.overlay = false;
              this.login = null;
              this.password = null;
              return false;
            }
            this.$store.commit("assignUserInfo", response.data.user);
            this.$store.commit("loggedIn");
            this.$store.commit("assignAccessToken", response.data.access_token);
            this.$store.commit("assignForwarding", this.subdomainSecondLayer);

            if( this.subdomainSecondLayer ) {
              if (this.$store.state.role === "user") {
                location.href = "/fileupload_for_forwarding";
              } else {
                location.href = "/tickets_for_forwarding";
              }
            } else {
              if (this.$store.state.role === "user") {
                location.href = "/fileupload";
              } else {
                location.href = "/tickets";
              }
            }
          } else {
            alert("Invalid user...");
            this.login = null;
            this.password = null;
          }
        })
        .catch((error) => {
          if (error) {
            const payload = {
              title: "Failed to log in...",
              msg: "Please contact with System Administrator...",
            };
            this.$store.commit("showAlert", payload);
          }
          this.overlay = false;
          return false;
        });
    },
  },
};
</script>
