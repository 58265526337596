<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card flat>
      <v-container>
        <v-toolbar flat dark class="indigo lighten-3">
          <v-toolbar-title> Select Your ShippingLine / Agency</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item-group color="primary" active-class="border">
              <v-list-item
                v-for="(s, i) in shippingLines"
                :key="i"
                link
                :href="`http://${s.subdomain}.systembase-ph.com/login`"
                target="_blank"
              >
                <v-list-item-icon><v-icon>mdi-domain</v-icon></v-list-item-icon>
                <v-list-item-content> {{ s.name }}</v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-container>
    </v-card>

    <!--DetailDialog start-->
    <v-dialog v-model="detailDialog" width="60%">
      <v-card flat>
        <v-container>
          <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title>window title</v-toolbar-title>
          </v-toolbar>
          <v-card-text>text</v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <!--DetailDialog finish-->
  </div>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      detailDialog: false,
      shippingLines: [],
    };
  },
  mounted() {
    axios
      .post("/api/get_shipping_lines_for_portal_entry")
      .then((response) => {
        this.shippingLines = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style scoped>
.border {
  border: 2px dashed orange;
}
</style>