import Vue from 'vue'
import Router from 'vue-router'
import Store from '../store/index.js'
import Login from '../pages/Login.vue'
import SignUp from '../pages/SignUp.vue'
import VerifyAccount from '../pages/VerifyAccount.vue'
import Forget from '../pages/Forget.vue'
import PortalEntry from '../pages/PortalEntry.vue'
import FileUploadingForm from '../pages/FileUploadingForm.vue'
import Tickets from '../pages/Tickets.vue';
import FileUploadingFormForForwarding from '../pages/FileUploadingFormForForwarding.vue'
import TicketsForForwarding from '../pages/TicketsForForwarding.vue';
import PendingDocuments from '../pages/PendingDocuments.vue';
import PendingDocumentsForForwarding from '../pages/PendingDocumentsForForwarding.vue';
import Profile from '../pages/Profile.vue';
import ParseEdi from '../pages/admin/ParseEdi.vue';
import Users from '../pages/admin/Users.vue';
import Payments from '../pages/admin/Payments.vue';
import Containers from '../pages/admin/Containers.vue';
import BlNumbers from '../pages/admin/BlNumbers.vue';
import HblNumbers from '../pages/admin/HblNumbers.vue';
import Documents from '../pages/admin/Documents.vue';
import PdfEditor from '../pages/admin/PdfEditor.vue';
import ContainerYards from '../pages/admin/ContainerYards.vue';
import ApiToken from '../pages/admin/ApiToken.vue';
// import Cesi from '../pages/admin/cesi/Index.vue';
import ChangePassword from '../pages/ChangePassword.vue';
import Payment from '../pages/Payment.vue';
import RedirectedFromDragonpay from '../pages/RedirectedFromDragonpay.vue';
import Dashboard from '../pages/Dashboard.vue';

Vue.use(Router)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/sign_up',
        name: 'SignUp',
        component: SignUp,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/change_password/:email',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/verify_account/:email',
        name: 'VerifyAccount',
        component: VerifyAccount,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/forget',
        name: 'Forget',
        component: Forget,
        meta: {
            isPublic: true
        }
    },
    // {
    //     path: '/',
    //     name: 'PortalEntry',
    //     component: PortalEntry,
    //     meta: {
    //         isPublic: true
    //     }
    // },
    {
        path: '/fileupload',
        name: 'FileUploadingForm',
        component: FileUploadingForm,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/fileupload_for_forwarding',
        name: 'FileUploadingFormForForwarding',
        component: FileUploadingFormForForwarding,
        meta: {
            isPublic: false
        }
    },    
    {
        path: '/tickets',
        name: 'Tickets',
        component: Tickets,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/tickets_for_forwarding',
        name: 'TicketsForForwarding',
        component: TicketsForForwarding,
        meta: {
            isPublic: false
        }
    },    
    {
        path: '/tickets/:transaction_id',
        name: 'Tickets2',
        component: Tickets,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/pending_documents',
        name: 'PendingDocuments',
        component: PendingDocuments,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/pending_documents_for_forwarding',
        name: 'PendingDocumentsForForwarding',
        component: PendingDocumentsForForwarding,
        meta: {
            isPublic: false
        }
    },    
    {
        path: '/pending_documents/:transaction_id',
        name: 'PendingDocuments2',
        component: PendingDocuments,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/admin/container_yards',
        name: 'ContainerYards',
        component: ContainerYards,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/admin/parse_edi',
        name: 'ParseEdi',
        component: ParseEdi,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/admin/users',
        name: 'Users',
        component: Users,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/admin/payments',
        name: 'Payments',
        component: Payments,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/admin/containers',
        name: 'Containers',
        component: Containers,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/admin/bl_numbers',
        name: 'BlNumbers',
        component: BlNumbers,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/admin/hbl_numbers',
        name: 'HblNumbers',
        component: HblNumbers,
        meta: {
            isPublic: false
        }
    },    
    {
        path: '/admin/documents',
        name: 'Documents',
        component: Documents,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/admin/pdf_editor',
        name: 'PdfEditor',
        component: PdfEditor,
        meta: {
            isPublic: false
        }
    },    
    {
        path: '/admin/api_token',
        name: 'ApiToken',
        component: ApiToken,
        meta: {
            isPublic: false
        }
    },        
    {
        path: '/payment',
        name: 'Payment',
        component: Payment,
        meta: {
            isPublic: false
        }
    },
    {
        path: '/redirect',
        name: 'RedirectedfromDragonpay',
        component: RedirectedFromDragonpay,
        meta: {
            isPublic: true,
        }
    },
    // {
    //     path: '/admin/dashboard',
    //     name: 'Dashboard',
    //     component: Dashboard,
    //     meta: {
    //         isPublic: true,
    //     }
    // },
    // {
    //     path: '/admin/cesi',
    //     name: 'Cesi',
    //     component: Cesi,
    //     meta: {
    //         isPublic: false
    //     }
    // },
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path.match(/admin/)) {
        if (Store.state.role !== 'admin') {
            alert('You are not allowed to enter the area...');
            return false;
        }
    }
    if (to.matched.some(page => page.meta.isPublic || Store.state.isLogged)) {
        next()
    } else {
        next('/login')
    }
});

export default router
