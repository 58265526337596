<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card flat>
      <v-container>
        <v-toolbar flat dark class="indigo lighten-3">
          <v-toolbar-title> File Uploading Form </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title> Your BL Number </v-toolbar-title>
          </v-toolbar>
          <v-text-field
            dense
            outlined
            placeholder="BL Number..."
            class="my-2"
            v-model="bl_number"
            @keyup="searchBlNumber()"
          ></v-text-field>
        </v-card-text>

        <div v-show="blMatched">
          <v-card-text>
            <v-toolbar flat dark class="indigo lighten-3">
              <v-toolbar-title> Your Temporary Transaction ID </v-toolbar-title>
            </v-toolbar>

            <v-card flat>
              <v-tabs v-model="tabs" fixed-tabs>
                <v-tabs-slider></v-tabs-slider>
                <v-tab href="#new-transaction" class="primary--text">
                  Start new transaction
                </v-tab>
                <v-tab href="#previous-transaction" class="primary--text">
                  View your previous transaction(s)
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabs">
                <v-tab-item value="new-transaction">
                  <v-card flat>
                    <v-card-text>
                      <v-text-field
                        placeholder="Your Transaction ID"
                        v-model="transactionId"
                        dense
                        outlined
                        class="my-2"
                        readonly
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item value="previous-transaction">
                  <v-card flat>
                    <v-card-text>
                      <v-select
                        placeholder="Previous TransactionId's"
                        dense
                        outlined
                        :items="transactions"
                        item-text="transaction_id"
                        item-value="transaction_id"
                        v-model="transactionSelected"
                        @change="getDocumentCategory()"
                      ></v-select>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>

            <v-card flat>
              <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title> Provide Container Number </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  dense
                  outlined
                  v-model="containerNumber"
                  placeholder="Container Number"
                ></v-text-field>
                <v-btn
                  block
                  dark
                  class="red lighten-3"
                  @click="registerContainer()"
                  >register</v-btn
                >
              </v-card-text>
              <v-card-text>
                <v-data-table
                  :headers="containerHeaders"
                  :items="containerItems"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="green"
                          @click="openPullOutSchedulingDialog(item)"
                          v-on="on"
                          >mdi-pencil</v-icon
                        >
                      </template>
                      <span>Open PullOutSchedulingDialog</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          @click="deleteContainer(item)"
                          color="red"
                          v-on="on"
                          >mdi-delete</v-icon
                        >
                      </template>
                      <span>Delete Container</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.pullout_schedule_date`]="{ item }">
                    {{ item.pullout_schedule_date }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>

            <v-toolbar flat dark class="indigo lighten-3">
              <v-toolbar-title> Select Transaction Category </v-toolbar-title>
            </v-toolbar>
            <v-list-item-group color="primary">
              <div v-if="tabs === 'new-transaction'">
                <v-list-item
                  v-for="(c, i) in documentCategories"
                  :key="i"
                  @click="selectCategory(c, null)"
                  :disabled="
                    transactionId === null || containerItems.length === 0
                  "
                >
                  <v-list-item-icon
                    ><v-icon>mdi-file-document</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>{{ c.name }}</v-list-item-content>
                </v-list-item>
              </div>
              <div v-if="tabs === 'previous-transaction'">
                <v-list-item>
                  <v-list-item-icon
                    ><v-icon>mdi-file-document</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>{{
                    selectedCategory
                  }}</v-list-item-content>
                </v-list-item>
              </div>
            </v-list-item-group>
          </v-card-text>

          <v-card-text>
            <v-toolbar flat dark color="indigo lighten-3">
              <v-toolbar-title>File(s) To Be Uploaded</v-toolbar-title>
            </v-toolbar>
            <v-list v-for="(d, i) in documents" :key="`file${d.id}`">
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col cols="10">
                      <v-file-input
                        dense
                        outlined
                        chips
                        multiple
                        v-model="userFiles[i]"
                        :placeholder="`${d.title}(${d.description})`"
                        accept=".pdf"
                        @change="addFiles(d, i)"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        @click="skipUploading(d, i)"
                        dark
                        class="red lighten-3"
                        v-show="d.skip"
                    >
                        <v-icon>mdi-close-octagon</v-icon>skip
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-progress-linear
                    height="15"
                    :value="progressLinears[i]"
                    dark
                    stream
                    class="my-2"
                  ></v-progress-linear>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-card-text>
            <v-toolbar flat dark color="indigo lighten-3">
              <v-toolbar-title>File(s) Registered</v-toolbar-title>
            </v-toolbar>
            <v-data-table :headers="headers" :items="items" :loading="loading">
              <template v-slot:top>
                <p>
                  <v-icon large color="red">mdi-stop</v-icon> = not yet started
                  <v-icon large color="yellow">mdi-pause</v-icon> = pending
                  <v-icon large color="green">mdi-check-bold</v-icon> = ok
                </p>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-icon v-if="item.status === 'pending'" large color="yellow"
                  >mdi-pause</v-icon
                >
                <v-icon v-else-if="item.status === 'ok'" large color="green"
                  >mdi-check-bold</v-icon
                >
                <v-icon
                  v-else-if="item.status === 'not yet started'"
                  large
                  color="red"
                  >mdi-stop</v-icon
                >
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn dark color="red lighten-3" icon v-if="!item.skipped">
                      <v-icon @click="deleteDocument(item)" v-on="on"
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Delete this document</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn dark color="red lighten-3" icon v-if="item.skipped">
                      <v-icon @click="deleteSkippedDocument(item)" v-on="on"
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Delete this document</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn dark color="green lighten-3" icon v-if="!item.skipped">
                      <v-icon @click="downloadDocument(item)" v-on="on"
                        >mdi-download</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Download this document</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.skipped`]="{ item }">
                {{ item.skipped | skipped }}
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions>
            <v-btn
              class="mb-15"
              block
              color="red lighten-3"
              dark
              :disabled="documents.length > 0 || items.length === 0"
              @click="issueTicket()"
              >issue ticket</v-btn
            >
          </v-card-actions>
        </div>
      </v-container>
    </v-card>

    <!--ContainerPullOutScheduling & Invoicing Dialog start-->
    <v-dialog v-model="pullOutSchedulingDialog" width="50%">
      <v-card>
        <v-container fluid>
          <v-toolbar flat color="indigo lighten-3" dark>
            <v-toolbar-title
              >Container Pull-Out Scheduling *{{
                containerNumber
              }}</v-toolbar-title
            >
          </v-toolbar>
          <v-form ref="form">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <br />
                <v-text-field
                  v-model="pulloutScheduleDate"
                  label="Choose PullOut Schedule Date..."
                  prepend-inner-icon="event"
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  required
                  :rules="rules"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="pulloutScheduleDate"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
            <v-btn block @click="updateContainerItems()" class="my-2 success">
              <v-icon>mdi-plus</v-icon>add pull-out schedule date
            </v-btn>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    <!--ContainerPullOutScheduling & Invoicing Dialog finish-->
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
export default {
  data() {
    return {
      overlay: false,
      loading: false,
      tabs: null,
      bl_number: null,
      blMatched: false,
      transactionId: null,
      containerNumber: null,
      containerNumbers: [],
      documentCategories: [],
      selectedCategory: null,
      documentCategoryId: null,
      documents: [],
      userFiles: [],
      progressLinears: [],
      file_id: null,

      transactions: [],
      transactionSelected: null,

      headers: [
        {
          text: "Title",
          value: "document.title",
          sortable: true,
        },
        {
          text: "Description",
          value: "document.description",
          sortable: true,
        },
        {
          text: "Name",
          value: "original_name",
          sortable: true,
        },
        {
          text: "Status",
          value: "status",
          sortable: true,
        },
        {
          text: "Remark",
          value: "remark",
          sortable: true,
        },
        {
          text: "Skipped",
          value: "skipped",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      items: [],
      containerHeaders: [
        {
          text: "ContainerNumber",
          value: "container_number",
          sortable: true,
        },
        {
          text: "PulloutScheduleDate",
          value: "pullout_schedule_date",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: true,
        },
      ],
      containerItems: [],

      pullOutSchedulingDialog: false,
      menu: null,
      pulloutScheduleDate: null,
      rules: [(v) => !!v || "Required!"],
      index: null,
    };
  },
  mounted() {
    // console.log(this.$store.state.access_token);
    this.overlay = true;
    this.loading = true;

    axios
      .post(
        "/api/get_document_categories",
        {
          'process_kind': 'shipping_line'
        }
      )
      .then((response) => {
        this.documentCategories = response.data;
        this.overlay = false;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.overlay = false;
        this.loading = false;
      });
  },
  methods: {
    searchBlNumber: function () {
      axios
        .post("/api/search_bl_number", {
          bl_number: this.bl_number,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.length) {
              this.blMatched = true;
              const payload = {
                title: "BL Number matched!",
                msg: "Your BL Number found in our database. Now you can proceed your online process...",
              };
              this.$store.commit("showAlert", payload);
              this.getTransactions();
            } else {
              this.blMatched = false;
            }
          }
        })
        .catch((error) => {});
    },
    selectCategory: function (category, transaction_id) {
      this.selectedCategory = category.name;
      this.documentCategoryId = category.id;
      this.overlay = true;
      this.loading = true;
      axios
        .post("/api/get_documents", {
          document_category_id: category.id,
          transaction_id: transaction_id,
        })
        .then((response) => {
          this.documents = response.data;
          this.overlay = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.overlay = false;
          this.loading = false;
        });

      this.getUploadedDocumentsForFileUploading();
    },
    addFiles: function (d, i) {
      this.file_id = i;
      let j = 0;
      this.formData = new FormData();
      for (var file of this.userFiles[i]) {
        if( typeof file === 'undefined' ) {
            return false;
        }
        if (!file.name.match(/pdf$/i)) {
          const payload = {
            title: "Invalid file chosen..",
            msg: "You tried to upload invalid type of file...you can upload PDF only..",
          };
          this.$store.commit("showAlert", payload);
          return false;
        } else {
          this.formData.append("file" + j, file);
          j++;
        }
      }

      this.formData.append(
        "transaction_id",
        this.tabs === "new-transaction"
          ? this.transactionId
          : this.transactionSelected
      );
      this.formData.append("user_id", this.$store.state.user_id);
      this.formData.append("bl_number", this.bl_number);
      this.formData.append('agent_code', this.$store.state.agent_code);
      this.formData.append("document_id", d.id);
      this.formData.append("title", d.title);
      this.formData.append("document_category_id", this.documentCategoryId);
      this.formData.append(
        "container_numbers",
        JSON.stringify(this.containerItems)
      ); // <--- amountに値が入っているコンテナ番号は全て対象とする
      this.formData.append("process_kind", "shipping_line");

      // console.log("i:" + i);
      // console.log("j:" + j);
      // for (var f of this.formData) {
      //   console.log(f);
      // }

      // return false;

      this.overlay = true;
      this.axios
        .post(
          "/api/save_files",
          this.formData,
          {
            onUploadProgress: this.onUpload,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          if (response.status === 200) {
            this.documents.splice(this.documents.indexOf(d), 1);
            this.userFiles.splice(i, 1);
            this.progressLinears.splice(i, 1);

            this.getUploadedDocumentsForFileUploading();

            this.getTransactions();

            this.overlay = false;
          }
        });
    },
    onUpload: function (e) {
      this.$set(
        this.progressLinears,
        this.file_id,
        Math.floor((e.loaded / e.total) * 100)
      );
    },
    getTransactions: function () {
      axios
        .post("/api/get_transactions", {
          user_id: this.$store.state.user_id,
          bl_number: this.bl_number,
        })
        .then((response) => {
          this.transactions = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUploadedDocumentsForFileUploading: function () {
      let transaction_id = null;

      if (this.tabs === "new-transaction") {
        transaction_id = this.transactionId;
      } else {
        transaction_id = this.transactionSelected;
      }

      axios
        .post("/api/get_uploaded_documents_for_file_uploading", {
          transaction_id: transaction_id,
        })
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDocumentCategory: function () {
      const self = this;
      const transaction = this.transactions.filter(function (value) {
        return value.transaction_id === self.transactionSelected;
      });

      const dc = this.documentCategories.filter(function (value) {
        return value.id === transaction[0].document_category_id;
      });

      this.selectCategory(dc[0], this.transactionSelected);
      this.getUploadedDocumentsForFileUploading();
      //Set container_numbers..
      // this.containerItems = JSON.parse(transaction[0].container_numbers);
    },
    deleteDocument: function (item) {
      if (!confirm(`Is it ok to delete ${item.name} ?`)) {
        return false;
      }
      this.items.splice(this.items.indexOf(item), 1);
      axios
        .post("/api/delete_document", {
          transaction_id: item.transaction_id,
          document_id: item.document_id,
          name: item.name,
        })
        .then((response) => {
          this.getUploadedDocumentsForFileUploading();
          this.getTransactions();

          if (this.items.length === 0) {
            this.selectedCategory = null;
            this.documents = [];
            return false;
          }

          axios
            .post("/api/get_documents", {
              document_category_id: item.document_category_id,
              transaction_id:
                this.tabs === "new-transaction"
                  ? this.transactionId
                  : this.transactionSelected,
            })
            .then((response) => {
              this.documents = response.data;
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteSkippedDocument: function (item) {
      if (!confirm(`Is it ok to delete ${item.name} ?`)) {
        return false;
      }
      this.items.splice(this.items.indexOf(item), 1);
      axios
        .post("/api/delete_skipped_document", {
          transaction_id: item.transaction_id,
          document_id: item.document_id,
          name: item.name,
        })
        .then((response) => {
          this.getUploadedDocumentsForFileUploading();
          this.getTransactions();

          if (this.items.length === 0) {
            this.selectedCategory = null;
            this.documents = [];
            return false;
          }

          axios
            .post("/api/get_documents", {
              document_category_id: item.document_category_id,
              transaction_id:
                this.tabs === "new-transaction"
                  ? this.transactionId
                  : this.transactionSelected,
            })
            .then((response) => {
              this.documents = response.data;
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    downloadDocument: function (item) {
      this.overlay = true;
      this.downloading = true;
      axios
        .post(
          "/api/download_document",
          {
            transaction_id: item.transaction_id,
            name: item.name,
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          this.downloading = false;
          this.overlay = false;
          const fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.target = "_blank";
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
        });
    },
    issueTicket: function () {
      let transaction_id = null;

      if (this.containerItems.length === 0) {
        const payload = {
          title: "No container number added!",
          msg: "You need to include at least one container number in this transaction...",
        };
        this.$store.commit("showAlert", payload);
        return false;
      }

      const c = this.containerItems.filter(function (value) {
        return (
          value.pullout_schedule_date === null ||
          typeof value.pullout_schedule_date === "undefined"
        );
      });

      if (c.length) {
        const payload = {
          title: "PullOutScheduleDate is not set!",
          msg: "You need to set PullOutScheduleDate for the containers to be pulled out...",
        };
        this.$store.commit("showAlert", payload);
        return false;
      }

      if (this.tabs === "new-transaction") {
        transaction_id = this.transactionId;
      } else {
        transaction_id = this.transactionSelected;
        if (!this.containerItems.length) {
          const payload = {
            title: "Provide container number",
            msg: "You need to provide at least one container number...",
          };
          this.$store.commit("showAlert", payload);
          return false;
        }
      }
      this.overlay = true;
      this.loading = true;
      axios
        .post("/api/issue_ticket", {
          transaction_id: transaction_id,
          bl_number: this.bl_number,
          items: this.$store.state,
          email: this.$store.state.email,
          containers: JSON.stringify(this.containerItems),
        })
        .then((response) => {
          // console.log(response.data);
          this.containerNumber = null;
          this.bl_number = null;
          this.containerItems = [];
          this.getTransactions();
          // this.documentCategories = [];
          this.transactionId = uuidv4();
          this.documentCategory = null;
          this.documents = [];
          this.items = [];
          this.overlay = false;
          this.loading = false;
          const payload = {
            title: "Ticked issued.",
            msg: "You have successfully issued this ticket.",
          };
          this.$store.commit("showAlert", payload);
        })
        .catch((error) => {
          console.log(error);
          this.overlay = true;
          this.loading = true;
        });
    },
    deleteContainer: function (item) {
      const index = this.containerItems.indexOf(item);
      this.containerItems.splice(index, 1);
    },
    registerContainer: function () {
      axios
        .post("/api/check_container", {
          bl_number: this.bl_number,
          container_number: this.containerNumber,
        })
        .then((response) => {
          if (response.data.length) {
            this.containerItems.push({
              container_number: this.containerNumber,
            });
            this.containerNumber = null;
            const payload = {
              title: "Container Number matched!",
              msg: "Your Container Number found in our database. The number was added in the temporary data table.",
            };
            this.$store.commit("showAlert", payload);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openPullOutSchedulingDialog: function (item) {
      this.pullOutSchedulingDialog = true;
      this.containerNumber = item.container_number;
      this.index = this.containerItems.indexOf(item);
    },
    updateContainerItems: function () {
      this.$set(
        this.containerItems[this.index],
        "pullout_schedule_date",
        this.pulloutScheduleDate
      );
      this.pullOutSchedulingDialog = false;
    },
    skipUploading: function(d, i) {
      this.file_id = i;
      let j = 0;
      this.formData = new FormData();

      this.formData.append(
        "transaction_id",
        this.tabs === "new-transaction"
          ? this.transactionId
          : this.transactionSelected
      );
      this.formData.append("user_id", this.$store.state.user_id);
      this.formData.append("bl_number", this.bl_number);
      this.formData.append("document_id", d.id);
      this.formData.append("title", d.title);
      this.formData.append("document_category_id", this.documentCategoryId);
      this.formData.append("agent_code", this.$store.state.agent_code);
      this.formData.append("skipped", 1);
      this.formData.append(
        "container_numbers",
        JSON.stringify(this.containerItems)
      );
      this.formData.append("process_kind", "shipping_line");

      this.overlay = true;
      this.axios
        .post(
          "/api/skipped_file",
          this.formData,
          {
            onUploadProgress: this.onUpload,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.documents.splice(this.documents.indexOf(d), 1);
            this.userFiles.splice(i, 1);
            this.progressLinears.splice(i, 1);

            this.getUploadedDocumentsForFileUploading();

            this.getTransactions();

            this.overlay = false;
          }
        });
    }
  },
  watch: {
    tabs: function () {
      if (this.tabs === "previous-transaction") {
        this.transactionId = null;
      } else {
        this.transactionId = uuidv4();
      }
      this.transactionSelected = null;
      this.selectedCategory = null;
      this.items = [];
      this.documents = [];
    },
  },
  filters: {
    skipped: function(value) {
      if(value) {
        return 'yes'
      } else {
        return '-'
      }
    }
  }
};
</script>
