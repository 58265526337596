require('./bootstrap');
import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.css'
import '@mdi/font/css/materialdesignicons.css'
//import 'material-design-icons-iconfont/dist/material-design-icons.css'
//import '@mdi/font/css/materialdesignicons.css'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.headers.common["Authorization"] =
    "Bearer " + store.state.access_token;

axios.defaults.headers.get['Accept'] = 'application/json';

Vue.config.productionTip = false;
Vue.use(Vuetify);
Vue.use(VueAxios, axios)

new Vue({
    el: '#app',
    vuetify: new Vuetify(),
    router,
    store,
    components: { App },
    template: '<App/>'
});