<template>
  <div>
    <v-card>
      <v-container>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :search="search"
          fixed-header
          height="50vh"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:top>
            <v-toolbar flat color="indigo lighten-3" dark>
              <v-toolbar-title>HBL Numbers</v-toolbar-title>
            </v-toolbar>
            <v-btn class="red lighten-3 mt-2" dark block @click="showHblNumbers">
              <v-icon>mdi-plus</v-icon>add hbl numbers
            </v-btn>
            <v-text-field
              v-model="search"
              label="search"
              outlined
              class="my-3"
              append-icon="mdi-magnify"
              dense
            ></v-text-field>
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            {{item.updated_at | formatDate(item.updated_at)}}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon 
                  color="orange" 
                  @click="openEditHblNumberDialog(item)"
                  v-on="on"
                >mdi-pencil</v-icon>
              </template>
              <span>Edit HBL Number</span>
            </v-tooltip>
            
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon 
                  color="red" 
                  @click="deleteHblNumber(item)"
                  v-on="on"
                >mdi-delete</v-icon>
                </template>
                <span>Delete HBL Number</span>
            </v-tooltip>
          </template>          
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            total-visible="10"
          ></v-pagination>
          <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div>
      </v-container>
    </v-card>
    <!--Dialog Start-->
    <v-dialog v-model="dialog" width="60%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card flat>
        <v-container>
          <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title
              >Import XLSX</v-toolbar-title
            >
          </v-toolbar>
          <v-card-text>
            <v-file-input
              dense
              outlined
              placeholder="Select XLSX file..."
              v-model="userFile"
              @change="uploadXlsx"
            ></v-file-input>
            <v-btn 
              class="red lighten-3" 
              dark 
              block
              @click="saveInDatabase"
              :disabled="!cellValues.length"
            >
              <v-icon>mdi-content-save</v-icon>save in database
            </v-btn>
            <v-data-table
              :headers="cellHeaders"
              :items="cellValues"
              :items-per-page="100"
            ></v-data-table>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <!--Dialog finish-->

    <!--editDialog start-->
    <v-dialog v-model="editDialog" width="60%">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card flat>
        <v-container>
          <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title
              >Edit HBL Number</v-toolbar-title
            >
          </v-toolbar>
          <v-card-text>
            <v-text-field
              v-model="editId"
              placeholder="ID"
              outlined
              dense
              disabled
            ></v-text-field>            
            <v-text-field
              v-model="editBlNumber"
              placeholder="BL Number"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="editHblNumber"
              placeholder="HBL Number"
              outlined
              dense
            ></v-text-field>
          </v-card-text>    
          <v-card-actions>
            <v-btn
              class="red lighten-3"
              dark
              block
              @click="updateHblNumber"
            >
              <v-icon>mdi-update</v-icon>Update
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <!--editDialog finish-->
  </div>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      overlay: false,
      loading: false,
      dialog: false,
      BlNumber: null,
      search: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 30,
      userFile: null,
      headers: [
        {
          text: "BlNumber",
          value: "bl_number",
          sortable: true,
        },        
        {
          text: "HblNumber",
          value: "hbl_number",
          sortable: true,
        },
        {
          text: "updated_at",
          value: "updated_at",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        }
      ],
      items: [],
      cellHeaders: [
        {
          text: "BL NUMBER",
          value: "bl_number",
          sortable: false,
        },
        {
          text: "HBL NUMBER",
          value: "hbl_number",
          sortable: false,
        }
      ],
      cellValues: [],
      formData: null,
      editDialog: false,
      editId: null,
      editBlNumber: null,
      editHblNumber: null,
    };    
  },
  created() {
    // this.axios.defaults.headers.common["Authorization"] =
    //   "Bearer " + this.$store.state.access_token;
    this.loadData();
  },
  filters: {
    formatDate: function (date) {
      return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
    },
  },
  methods: {
    loadData: function() {
      this.overlay = true;
      this.loading = true;
      this.axios
        .post("/api/get_hbl_numbers", {
          agent_code: this.$store.state.agent_code,
        })
        .then((response) => {
          this.items = response.data;
          this.overlay = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.overlay = false;
          this.loading = false;
        });
    },
    showHblNumbers: function() {
      this.dialog = true;
      this.cellValues = [];
      this.userFile = null;      
    },
    uploadXlsx: function() {
      // console.log(this.userFile);
      if(!this.userFile) {
        this.cellValues = [];
        return false;
      };

      this.formData = new FormData();
      this.formData.append('file', this.userFile);
      this.formData.append('email', this.$store.state.email);
      this.axios.post(
        '/api/import_excel',
          this.formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }        
      )
        .then(response => {
          // console.log(response.data);
          this.cellValues = response.data;
        }).catch(error => {
          console.log(error);
        });      
    },
    saveInDatabase: function() {
      if(!confirm(
        'Is it ok to save these values in the database table?'
      )) {
        this.cellValues = [];
        this.userFile = null;
        return false;
      }

      this.axios.post(
        '/api/save_in_database',
        {
          cellValues: this.cellValues
        }
      )
        .then(response => {
          if(response.status === 200) {
            const payload = {
              title: "XLSX data registered Successfully.",
              msg: "Those BL & HBL Numbers have been successfully registered in the database table.",
            };
            this.$store.commit("showAlert", payload);
            this.dialog = false;
            this.loadData();
          }
        }).catch(error => {
            const payload = {
              title: "Failed...",
              msg: "Server error occured due to wrong formatted data or duplicate entry..",
            };
            this.$store.commit("showAlert", payload);   
            this.dialog = false;    
            return false;
        });
    },
    openEditHblNumberDialog: function(item) {
      this.editDialog = true;
      this.editId = item.id;
      this.editBlNumber = item.bl_number;
      this.editHblNumber = item.hbl_number;      
    },
    updateHblNumber: function() {
      if (!confirm('Is it ok to update this record?')) {
        this.editDialog = false;
        return false;
      } 

      this.axios.post(
        '/api/update_hbl_number',
        {
          id: this.editId,
          bl_number: this.editBlNumber,
          hbl_number: this.editHblNumber
        }
      )
        .then(response => {
          // console.log(response.data);
          if ( response.status === 200 ) {
            const payload = {
              title: "Updated Successfully",
              msg: "The record has been updated successfully.",
            };
            this.$store.commit("showAlert", payload);
          }
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.editId = null;
          this.editBlNumber = null;
          this.editHblNumber = null;
          this.editDialog = false;
          this.loadData();
        });
    },
    deleteHblNumber: function(item) {
      if (!confirm('Is it ok to delete the record?')) return false;
      this.axios.post(
        '/api/delete_hbl_number',
        {
          id: item.id
        }
      )
        .then(response => {
          // console.log(response.data);
          if ( response.status === 200 ) {
            const payload = {
              title: "Record deleted",
              msg: "The record has been deleted successfully.",
            };
            this.$store.commit("showAlert", payload);            
            this.loadData();
          }
        }).catch(error => {
          console.log(error);
        });
    }
  },
};
</script>
