<template>
  <div>
    {{ $route.query }}
  </div>
</template>
<script>
export default {
  created() {
    if (this.$route.query.status === "F") {
      const payload = {
        title: "Technical Error",
        msg: "The payment process failed. Kindly inform the matter to system administrator...",
      };
      this.$store.commit("showAlert", payload);
      return false;
    }
    this.axios
      .post("/api/get_domain", {
        txnid: this.$route.query.txnid,
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.match(/systembase-ph\.com/)) {
          location.href =
            location.protocol +
            "//" +
            response.data +
            "/tickets?bl_number=" +
            this.$route.query.txnid.substr(14);
        } else {
          // In case of localhost:8000
          location.href =
            location.protocol +
            "//" +
            response.data +
            "/tickets?bl_number=" +
            this.$route.query.txnid.substr(14);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
