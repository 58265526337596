<template>
  <v-app-bar app dark color="black" clipped-left>
    <v-app-bar-nav-icon
      @click="$store.commit('toggleDrawer')"
      v-show="$store.state.isLogged"
    ></v-app-bar-nav-icon
    >{{ organizationName }}
    <v-spacer></v-spacer>

    <v-menu bottom dark>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          v-show="$store.state.role === 'admin' && $store.state.isLogged"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list v-for="(menu, i) in menus" :key="i">
        <v-list-item>
          <v-list-item-title>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon :to="menu.to" v-on="on" :disabled="!menu.disabled">
                  <v-icon>{{ menu.icon }}</v-icon>
                </v-btn>
              </template>
              <span>{{ menu.text }}</span>
            </v-tooltip>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon to="/login" v-show="$store.state.isLogged" v-on="on">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>
      <span>Logout</span>
    </v-tooltip>
  </v-app-bar>
</template>
<script>
export default {
  data() {
    return {
      organizationName: location.host,
      menus: [],
    };
  },
  mounted() {
    if (this.$store.state.forwarding) {
      this.menus = [
        {
          to: "/admin/hbl_numbers",
          icon: "mdi-database",
          text: "HblNumbers",
          disabled: this.$store.state.role === "admin",
        },        
        {
          to: "/admin/documents",
          icon: "mdi-file",
          text: "Document Master",
          disabled: this.$store.state.role === "admin",
        },
        {
          to: "/admin/users",
          icon: "mdi-account",
          text: "Users",
          disabled: this.$store.state.role === "admin",
        },
        // {
        //   to: "/admin/pdf_editor",
        //   icon: "mdi-pdf-box",
        //   text: "PDF Editor",
        //   disabled: this.$store.state.role === "admin",
        // },        
      ];
    } else {
      this.menus = [
        {
          to: "/admin/documents",
          icon: "mdi-file",
          text: "Document Master",
          disabled: this.$store.state.role === "admin",
        },
        {
          to: "/admin/container_yards",
          icon: "mdi-pier-crane",
          text: "Continer Yards",
          disabled: this.$store.state.role === "admin",
        },
        {
          to: "/admin/parse_edi",
          icon: "mdi-upload",
          text: "Upload EDI Files",
          disabled: this.$store.state.role === "admin",
        },
        {
          to: "/admin/users",
          icon: "mdi-account",
          text: "Users",
          disabled: this.$store.state.role === "admin",
        },
        {
          to: "/admin/containers",
          icon: "mdi-shipping-pallet",
          text: "Containers",
          disabled: this.$store.state.role === "admin",
        },
        {
          to: "/admin/bl_numbers",
          icon: "mdi-database",
          text: "BlNumbers",
          disabled: this.$store.state.role === "admin",
        },
        {
          to: "/admin/payments",
          icon: "mdi-cash-100",
          text: "Payments",
          disabled: this.$store.state.role === "admin",
        },
        {
          to: "/admin/pdf_editor",
          icon: "mdi-pdf-box",
          text: "PDF Editor",
          disabled: this.$store.state.role === "admin",
        },
        {
          to: "/admin/api_token",
          icon: "mdi-api",
          text: "API Token",
          disabled: this.$store.state.role === "admin",
        },              
      ];
    }

    this.menus.sort();
  },
};
</script>
