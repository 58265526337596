<template>
  <div>
    <v-card flat>
      <v-container>
        <v-toolbar flat dark class="indigo lighten-3">
          <v-toolbar-title>Container Yards</v-toolbar-title>
        </v-toolbar>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-text>
          <v-data-table :items="items" :headers="headers" :loading="loading">
            <template v-slot:top>
              <v-btn dark class="red lighten-3" @click="dialog = true">
                <v-icon>mdi-plus</v-icon>register container yard
              </v-btn>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    color="red"
                    @click="deleteContainerYard(item)"
                    v-on="on"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Delete Container Yard</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-container>
    </v-card>

    <!--Dialog start-->
    <v-dialog v-model="dialog" width="60%">
      <v-card flat>
        <v-container>
          <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title>Register Container Yard(s)</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              placeholder="container yard name"
              v-model="containerYard"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              dark
              class="red lighten-3"
              block
              :disabled="containerYard === null"
              @click="insertContainerYard()"
            >
              <v-icon>mdi-content-save</v-icon>save data
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <!--Dialog finish-->
  </div>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      loading: false,
      dialog: false,
      containerYard: null,
      headers: [
        {
          text: "ReturnTo",
          value: "return_empty_container_to",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: true,
        },
      ],
      items: [],
    };
  },
  mounted() {
    this.getContainerYards();
  },
  methods: {
    getContainerYards: function () {
      this.overlay = true;
      this.loading = true;
      axios
        .post("/api/get_container_yards")
        .then((response) => {
          this.items = response.data;
          this.overlay = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.overlay = false;
          this.loading = false;
        });
    },
    insertContainerYard: function () {
      axios
        .post("/api/insert_container_yard", {
          containerYard: this.containerYard,
        })
        .then((response) => {
          this.getContainerYards();
          this.containerYard = null;
          this.dialog = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteContainerYard: function (item) {
      axios
        .post("/api/delete_container_yard", {
          id: item.id,
        })
        .then((response) => {
          this.getContainerYards();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>