<template>
  <v-card>
    <v-container>
      <v-form ref="form">
        <v-toolbar flat color="indigo lighten-3" dark>
          <v-toolbar-title>Profile</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="name"
            label="Name"
            outlined
            dense
            :rules="rules"
          ></v-text-field>
          <v-text-field
            v-model="email"
            label="Email Address"
            outlined
            dense
            readonly
            :rules="emailRules"
          ></v-text-field>
          <v-text-field
            v-model="phone_number"
            label="Phone#"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="organization"
            label="Organization"
            outlined
            dense
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red lighten-3" dark @click="updateProfile">
            <v-icon>mdi-update</v-icon>Update
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
    <br />
    <br />
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      name: null,
      email: null,
      phone_number: null,
      organization: null,
      rules: [(v) => !!v || "Required"],
      emailRules: [(v) => /.+@.+/.test(v) || "Invalid Email address"],
    };
  },
  created() {
    axios
      .post("/api/get_profile", {
        id: this.$store.state.user_id,
      })
      .then((response) => {
        // console.log(response.data);
        this.name = response.data[0].name;
        this.email = response.data[0].email;
        this.phone_number = response.data[0].personal_phone_number;
        this.organization = response.data[0].organization;
        let user = {
          name: this.name,
          organization: this.organization,
        };
        this.$store.commit("updateProfile", user);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    updateProfile: function () {
      if (!this.$refs.form.validate()) {
        return false;
      }

      axios
        .post("/api/update_profile", {
          email: this.email,
          name: this.name,
          personal_phone_number: this.phone_number,
          organization: this.organization,
        })
        .then((response) => {
          if (response.status === 200) {
            const payload = {
              title: "Profile Updated!",
              msg: "Your profile updated successfully!",
            };
            this.$store.commit("showAlert", payload);
          }
        });
    },
  },
};
</script>
