import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            key: 'example',
            storage: localStorage
        })],
    state: {
        isLogged: false,
        user_id: null,
        name: null,
        email: null,
        role: null,
        super_user: null,
        organization: null,
        agent_code: null,
        forwarding: null,
        access_token: null,
        alert: {
            dialog: false,
            title: '',
            msg: ''
        },
        drawer: false
    },
    mutations: {
        updateProfile(state, profile) {
            state.name = profile.name;
            state.organization = profile.organization;
        },
        loggedIn(state) {
            state.isLogged = true;
            state.access_token = null;
        },
        loggedOut(state) {
            state.isLogged = false;
        },
        toggleDrawer(state) {
            state.drawer = !state.drawer;
        },
        assignUserInfo(state, user) {
            state.user_id = user.id;
            state.name = user.name;
            state.email = user.email;
            state.role = user.role;
            state.super_user = user.super_user;
            state.organization = user.organization;
            // ここはサブドメインで船会社の情報を取得できる様になるまでは保留。
            // state.agent_code = user.agent_code;
            state.agent_code = location.host.split('.')[0];
        },
        assignForwarding(state, forwarding) {
            state.forwarding =  forwarding;
        },
        assignAccessToken(state, token) {
            state.access_token = token;
        },
        showAlert(state, payload) {
            state.alert.dialog = true;
            state.alert.title = payload.title;
            state.alert.msg = payload.msg;
        },
        hideAlert(state) {
            state.alert.dialog = false;
        },
    }
});