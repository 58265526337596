var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","dark":"","color":"black","clipped-left":""}},[_c('v-app-bar-nav-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.isLogged),expression:"$store.state.isLogged"}],on:{"click":function($event){return _vm.$store.commit('toggleDrawer')}}}),_vm._v(_vm._s(_vm.organizationName)+"\n  "),_c('v-spacer'),_vm._v(" "),_c('v-menu',{attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.role === 'admin' && _vm.$store.state.isLogged),expression:"$store.state.role === 'admin' && $store.state.isLogged"}],attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_vm._v(" "),_vm._l((_vm.menus),function(menu,i){return _c('v-list',{key:i},[_c('v-list-item',[_c('v-list-item-title',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":menu.to,"disabled":!menu.disabled}},on),[_c('v-icon',[_vm._v(_vm._s(menu.icon))])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(menu.text))])])],1)],1)],1)})],2),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.isLogged),expression:"$store.state.isLogged"}],attrs:{"icon":"","to":"/login"}},on),[_c('v-icon',[_vm._v("mdi-logout")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("Logout")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }