<template>
  <v-app>
    <v-main app>
      <app-header></app-header>
      <app-drawer></app-drawer>
      <app-footer></app-footer>
      <app-alert></app-alert>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>
import Header from "./modules/Header.vue";
import Drawer from "./modules/Drawer.vue";
import Footer from "./modules/Footer.vue";
import Alert from "./modules/Alert.vue";

export default {
  components: {
    "app-header": Header,
    "app-drawer": Drawer,
    "app-footer": Footer,
    "app-alert": Alert,
  },
};
</script>
