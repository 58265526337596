<template>
  <div>
    <v-form>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="8" sm="8" md="8">
            <v-form ref="form">
              <v-card class="elevation-1">
                <v-toolbar class="indigo lighten-3" dark flat>
                  <v-toolbar-title>Payment Form</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    v-model="txnid"
                    label="Transaction ID"
                    name="txnid"
                    prepend-icon="mdi-identifier"
                    type="text"
                    readonly
                    :rules="rules"
                  />
                  <v-text-field
                    v-model="bl_number"
                    label="BL Number"
                    name="bl_number"
                    prepend-icon="mdi-identifier"
                    type="text"
                    readonly
                    :rules="rules"
                  />                  
                  <v-text-field
                    v-model="amount"
                    label="Amount"
                    name="password"
                    prepend-icon="mdi-cash-multiple"
                    type="number"
                    :rules="rules"
                    readonly
                  />
                  <v-text-field
                    v-model="description"
                    label="Description"
                    name="description"
                    prepend-icon="mdi-card-text"
                    type="text"
                    :rules="rules"
                    readonly
                  />
                  <v-text-field
                    label="Email"
                    name="email"
                    prepend-icon="mdi-email"
                    type="text"
                    v-model="email"
                    :rules="emailRules"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue lighten-3" dark @click="payNow()"
                    ><v-icon>mdi-credit-card-outline</v-icon> Pay Now</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      bl_number: null,
      txnid:
        // moment().format("YYYYMMDDHHmmss") + this.$route.query.transaction_id,
        moment().format("mmss") + this.$route.query.transaction_id,
      amount: this.$route.query.amount,
      description: location.host,
      //   email: this.$store.state.email,
      email: this.$store.state.email,
      rules: [(v) => !!v || "Required"],
      emailRules: [(v) => /.+@.+/.test(v) || "Invalid Email address"],
    };
  },
  mounted() {
    this.axios.post(
      '/api/get_bl_number', 
      {transaction_id: this.$route.query.transaction_id}
    ).then(response => {
        this.bl_number = response.data[0].bl_number;
      }).catch(error => {
        console.log(error);
      });
  },
  methods: {
    payNow: function () {
      if (!this.$refs.form.validate()) {
        return false;
      }
      this.axios
        .post("/api/redirect_to_dragonpay", {
          txnid: this.txnid,
          transaction_id: this.$route.query.transaction_id,
          agent_code: this.$store.state.agent_code,
          amount: this.amount,
          description: this.description,
          email: this.email,
        })
        .then((response) => {
          //   console.log(response.data);
          const url = response.data;
          window.open(url, "_self");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
