<template>
  <div>
    <v-card>
      <v-container>
        <v-card-text>
          <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title>Online PDF Editor</v-toolbar-title>
          </v-toolbar>
          <v-row>
            <v-col cols="5">
              <v-file-input 
                v-model="userFile"
                accept="application/pdf"
                class="mt-2" 
                dense 
                outlined 
                label="Upload PDF..."
                @change="setData"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols-="5">
              <v-pagination
                v-model="pageSelected"
                :length="numPages"
              ></v-pagination>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn-toggle
                v-model="drawingMode"
                dense
                dark
              >
                <v-btn small>
                  <v-icon>mdi-vector-line</v-icon> straight line
                </v-btn>
                <v-btn small>
                  <v-icon>mdi-vector-rectangle</v-icon> rectangle
                </v-btn>
                <v-btn small>
                  <v-icon>mdi-vector-circle</v-icon>circle
                </v-btn>
                <v-btn small>
                  <v-icon>mdi-format-text</v-icon>text
                </v-btn>
                <v-btn small>
                  <v-icon>mdi-image-plus</v-icon>image
                </v-btn>
              </v-btn-toggle>

              <v-btn-toggle dark dense>
                <v-btn @click="unDo" small>
                  <v-icon>mdi-undo</v-icon>undo
                </v-btn>
                <v-btn @click="reDo" small>
                  <v-icon>mdi-redo</v-icon>redo
                </v-btn>
                <v-menu offset-y>
                  <template v-slot:activator="{on}">
                    <v-btn v-on="on" :color="color.hexa" small>
                      <v-icon>mdi-palette</v-icon>color picker
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-color-picker
                        class="ma-2"
                        show-swatches
                        v-model="color"
                      ></v-color-picker>                   
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <canvas 
                id="myCanvas"
                ref="el" 
                :class="cursorStyle"
                @mousedown="mouseDown"
                @mousemove="mouseMove"
                @mouseleave="mouseLeave"
                @mouseup="mouseUp"
              ></canvas>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>

    <!--- Dialog start --->
    <v-dialog v-model="textDialog" width="30%">
      <v-card flat>
        <v-container>
          <v-toolbar color="indigo lighten-2" dark>
            <v-toolbar-title>test</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field dense outlined placeholder="Type text..." v-model="text"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red lighten-2" @click="drawText">insert</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <!--- Dialog end -->
  </div>
</template>

<script>
  import * as pdfjsLib from "pdfjs-dist";

  export default {
    name: 'Home',
    data() {
      return {
        textDialog: false,
        ctx: null,
        drawingMode: 0,
        cursorStyle: 'crosshair',
        isMouseDown: false,
        rect: null,
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
        color: { "alpha": 1, "hex": "#FF0000", "hexa": "#FF0000FF", "hsla": { "h": 0, "s": 1, "l": 0.5, "a": 1 }, "hsva": { "h": 0, "s": 1, "v": 1, "a": 1 }, "hue": 0, "rgba": { "r": 255, "g": 0, "b": 0, "a": 1 } },
        tmpObj: {},
        objects: [],
        objects2: [],
        text: '',
        img: null,
        url: null,
        userFile: null,
        data: null,
        numPages: null,
        pageSelected: 1,
        json: [],
      }
    },
    components: {

    },
    methods: {
      mouseDown: function (e) {
        this.isMouseDown = true;
        this.rect = e.target.getBoundingClientRect();
        this.startX = e.clientX - this.rect.left;
        this.startY = e.clientY - this.rect.top;

        if(this.drawingMode === 3) {
          this.textDialog = true;
        }
      },      
      mouseMove: function (e) {
        if (!this.isMouseDown) return;
        this.endX = e.clientX - this.rect.left;
        this.endY = e.clientY - this.rect.top;
        switch ( this.drawingMode ) {
          case 0:
            this.ctx.lineWidth = 2;
            this.drawStraightLine(e);      
            break;
          case 1:
            this.ctx.lineWidth = 2;
            this.drawRectOutline(e);                        
            break;
          case 2:
            this.ctx.lineWidth = 2;
            this.drawCircleOutline();
            break;
          case 3:
            // this.drawText();
            // this.textDialog = true;
            break;
          case 4:
            this.drawImage();
            break;
          default:
            break;
        }
      },
      mouseUp: function () {
        this.isMouseDown = false;
        this.saveObject();
      },      
      mouseLeave: function () {
        this.isMouseDown = false;
      },
      drawStraightLine() {
        this.clearCanvas();
        this.reDraw();     
        this.ctx.beginPath();
        this.ctx.strokeStyle = this.color.hexa;
        this.ctx.moveTo(this.startX, this.startY);
        this.ctx.lineTo(this.endX, this.endY);
        this.ctx.stroke();

        this.tmpObj = {
          type: 'straightLine',
          color: this.color.hexa,
          startX: this.startX,
          startY: this.startY,
          endX: this.endX,
          endY: this.endY
        }          
      },     
      drawRectOutline() {
        this.clearCanvas();
        this.reDraw();
        this.ctx.beginPath();
        this.ctx.strokeStyle = this.color.hexa;
        this.ctx.rect(
          this.startX, 
          this.startY,
          this.endX - this.startX,
          this.endY - this.startY
        );
        this.ctx.stroke();

        this.tmpObj = {
          type: 'rectangle',
          color: this.color.hexa,
          startX: this.startX,
          startY: this.startY,
          endX: this.endX,
          endY: this.endY
        }
      },
      drawCircleOutline() {
        this.clearCanvas();
        this.reDraw();        
        this.ctx.beginPath();
        this.ctx.strokeStyle = this.color.hexa;
        this.ctx.arc(this.startX, this.startY, Math.abs(this.endX - this.startX), 0, 2 * Math.PI);
        this.ctx.stroke();

        this.tmpObj = {
          type: 'circle',
          color: this.color.hexa,
          startX: this.startX,
          startY: this.startY,
          endX: this.endX,
          endY: this.endY
        }
      },      
      drawText() {
        this.ctx.font = "30px Arial";
        this.ctx.fillStyle = this.color.hexa;
        this.ctx.fillText(this.text, this.startX, this.startY);
        this.textDialog = false;

        this.tmpObj = {
          type: 'text',
          text: this.text,
          color: this.color.hexa,
          startX: this.startX,
          startY: this.startY
        }

        this.saveObject();
      },
      drawImage() {
        this.img = new Image();
        this.img.src = require('../../../assets/logo.png');
        const self = this;
        this.img.onload = function () {
          self.ctx.drawImage(self.img, self.startX, self.startY);
        }
      },
      saveObject: function () {
        this.objects.push(this.tmpObj);
        this.tmpObj = {};
        // console.log(this.objects)
      },
      clearCanvas: function () {
        this.ctx.clearRect(0, 0, this.$refs.el.width, this.$refs.el.height);
      },
      reDraw: function () {
        for( const obj of this.objects ) {
          switch ( obj.type ) {
            case 'straightLine':
              this.ctx.beginPath();
              this.ctx.strokeStyle = obj.color;
              this.ctx.moveTo(obj.startX, obj.startY);
              this.ctx.lineTo(obj.endX, obj.endY);
              this.ctx.stroke(); 
              break;
            case 'rectangle':
              this.ctx.beginPath();
              this.ctx.strokeStyle = obj.color;
              this.ctx.rect(
                obj.startX, 
                obj.startY,
                obj.endX - obj.startX,
                obj.endY - obj.startY
              );
              this.ctx.stroke();              
              break;
            case 'circle':
              this.ctx.beginPath();
              this.ctx.strokeStyle = obj.color;
              this.ctx.arc(obj.startX, obj.startY, Math.abs(obj.endX - obj.startX), 0, 2 * Math.PI);
              this.ctx.stroke();              
              break;
            case 'text':
              this.ctx.font = "30px Arial";
              this.ctx.fillStyle = obj.color;
              this.ctx.fillText(obj.text, obj.startX, obj.startY);              
              break;
            default:
              break;
          }
        }
      },
      unDo: function () {
        const lastIndex = this.objects.length - 1;
        const lastElm = this.objects[lastIndex];
        if ( lastElm === undefined ) {
          return false;
        }
        this.objects2.push(lastElm);
        
        this.objects.splice(lastIndex, 1);
        this.clearCanvas();
        this.reDraw();
      },
      reDo: function () {
        if(!this.objects2.length) {
          return false;
        }
        const lastIndex = this.objects2.length - 1;
        const lastElm = this.objects2[lastIndex];
        this.objects2.splice(lastIndex, 1);
        this.objects.push(lastElm);
        this.clearCanvas();
        this.reDraw();
      },
      setData: function() {
        const formData = new FormData();
        formData.append('file', this.userFile);

        this.axios.post(
          '/api/upload_pdf',
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }          

        ).then(response => {
            console.log(response.data);
          }).catch(error => {
            console.log(error);
          });
      },
      // setData: function() {
      //   this.url = URL.createObjectURL(this.userFile);
      //   pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.js';
      //   this.data = pdfjsLib.getDocument({url: this.url});
      //   this.setNumPages();
      // },
      // setNumPages: function() {
      //   const self = this;
      //   this.data.promise.then(function(pdf) {
      //     self.numPages = pdf.numPages;
      //     // for (var i = 0; i < self.numPages; i++) {
      //     //   self.items.push( i + 1 );
      //     // }
      //     self.setJson(self.numPages);
      //   });      
      // },
      // setPageContent: function(pageNo) {

      //   const self = this;

      //   this.data.promise.then(function(pdf) {
      //     pdf.getPage(pageNo).then(function(page) {

      //       var PRINT_UNITS = 150.0 / 72.0;
      //       var scale = 0.5;
      //       var viewport = page.getViewport({scale: scale});
      //       var canvas = document.getElementById('myCanvas');
      //       var ctx = canvas.getContext('2d');
      //       canvas.height = viewport.height * 2;
      //       canvas.width = viewport.width * 2;
      //       // canvas.height = 600;
      //       // canvas.width = 600;

      //       var renderContext = {
      //         canvasContext: ctx,
      //         transform: [PRINT_UNITS, 0, 0, PRINT_UNITS, 0, 0],
      //         viewport: viewport
      //       };

      //       self.json.push(renderContext);
      //     });
      //   });
      // },
      // setJson: function(num) {
      //   for(let i = 0; i < num; i++) {
      //     this.setPageContent(i + 1);
      //     // console.log(this.json)
      //   }
      //   this.drawPage(1);
      // },
      // drawPage: function(pageNo) {
      //   this.data.promise.then(function(pdf) {
      //     pdf.getPage(pageNo).then(function(page) {

      //       var PRINT_UNITS = 150.0 / 72.0;
      //       var scale = 0.5;
      //       var viewport = page.getViewport({scale: scale});
      //       var canvas = document.getElementById('myCanvas');
      //       var ctx = canvas.getContext('2d');
      //       canvas.height = viewport.height * 2;
      //       canvas.width = viewport.width * 2;
      //       // canvas.height = 600;
      //       // canvas.width = 600;

      //       var renderContext = {
      //         canvasContext: ctx,
      //         transform: [PRINT_UNITS, 0, 0, PRINT_UNITS, 0, 0],
      //         viewport: viewport
      //       };

      //       var renderTask = page.render(renderContext);
      //       renderTask.promise.then(function() {
      //         // console.log('page rendered');
      //       });
      //     });
      //   });      
      // }
    },
    mounted() {
      this.ctx = this.$refs.el.getContext("2d");
      this.$refs.el.width = 850;
      this.$refs.el.height = 600;
      
    },
    watch: {
      drawingMode: function() {
        // console.log(this.drawingMode)
        switch(this.drawingMode) {
          case 0:
            this.cursorStyle = 'text';
            break;
          case 1:
            this.cursorStyle = 'crosshair';
            break;
          case 2:
            this.cursorStyle = 'crosshair';
            break;
          case 3:
            this.cursorStyle = 'text';
            break;
          case 4:
            this.cursorStyle = 'text';
            break;
          case 5:
            this.cursorStyle = 'text';
            break;
          case 6:
            this.cursorStyle = 'context-menu';
            break;
          default:
            break;                                                            
        }
      },
      pageSelected: function(value) {
        this.drawPage(value);
      }      
    }
  }
</script>
<style scoped>
canvas {
  border: black solid 1px;
}

.context-menu {
  cursor: context-menu;
}
.crosshair {
  cursor: crosshair;
}
.text {
  cursor: text;
}
</style>
