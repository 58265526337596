<template>
  <v-card>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container>
      <v-toolbar flat color="indigo lighten-3" dark>
        <v-toolbar-title>EDI File Uploading Form</v-toolbar-title>
      </v-toolbar>
      <v-tabs
        v-model="tab"
        background-color="indigo"
        dark
        centered
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-1">
          XML format
        </v-tab>
        <v-tab href="#tab-2">
          Excel format
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!-- XML start -->
        <v-tab-item value="tab-1">
          <v-card-text>
            <v-card
              class="elevation-5"
              color="indigo lighten-4 pa-2"
              height="100%"
              width="100%"
              @dragover.prevent
              @drop.stop.prevent="onDrop"
            >
              <v-card-title class="justify-center">
                <div>
                  <v-icon size="200">mdi-cloud-upload</v-icon>
                  <v-spacer></v-spacer>drag & drop file(s) here
                </div>
              </v-card-title>
              <v-card-text class="text-center headeline">
                OR
                <br />
                <br />
                <v-btn color="primary" @click="openFileDialog">
                  <v-icon>mdi-file-upload</v-icon>Add file(s)
                </v-btn>
              </v-card-text>
            </v-card>

            <input
              type="file"
              multiple
              ref="fileInput"
              @change="onSelect"
              style="display: none"
            />
            <v-row>
              <v-col class="text-center">
                <v-progress-circular
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="percentage"
                  color="teal"
                  >{{ percentage }}%</v-progress-circular
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-progress-linear height="15" v-model="percentage2" dark stream
                  >{{ percentage2 }}% uploaded</v-progress-linear
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-list dense>
                  <v-list-item v-for="(link, i) in hyperLinks" :key="i">
                    <v-list-item-icon>
                      <v-icon>mdi-file</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content v-html="link"></v-list-item-content>
                    <v-list-item-action>
                      <v-btn @click="deleteFile(i)" icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-right">
                <v-btn
                  color="red"
                  dark
                  @click="sendFiles"
                  v-show="hyperLinks.length"
                  class="mb-5"
                >
                  <v-icon>mdi-file-upload</v-icon>SEND
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>
        <!-- XML finish -->

        <!-- Excel start -->
        <v-tab-item value="tab-2">
          <v-card-text>
            <v-card>
            <v-container>
                <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
                <v-data-table
                :headers="headers"
                :items="items"
                :loading="loading"
                :search="search"
                fixed-header
                height="50vh"
                hide-default-footer
                :page.sync="page"
                @page-count="pageCount = $event"
                >
                <template v-slot:top>
                    <v-toolbar flat color="indigo lighten-3" dark>
                    <v-toolbar-title>HBL Numbers</v-toolbar-title>
                    </v-toolbar>
                    <v-btn class="red lighten-3 mt-2" dark block @click="showContainerNumbers">
                    <v-icon>mdi-plus</v-icon>add container numbers
                    </v-btn>
                    <v-text-field
                    v-model="search"
                    label="search"
                    outlined
                    class="my-3"
                    append-icon="mdi-magnify"
                    dense
                    ></v-text-field>
                </template>
                <template v-slot:[`item.updated_at`]="{ item }">
                    {{item.updated_at | formatDate(item.updated_at)}}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon
                        color="orange"
                        @click="openEditContainerNumberDialog(item)"
                        v-on="on"
                        >mdi-pencil</v-icon>
                    </template>
                    <span>Edit HBL Number</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon
                        color="red"
                        @click="deleteHblNumber(item)"
                        v-on="on"
                        >mdi-delete</v-icon>
                        </template>
                        <span>Delete HBL Number</span>
                    </v-tooltip>
                </template>
                </v-data-table>
                <div class="text-center pt-2">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    total-visible="10"
                ></v-pagination>
                <v-text-field
                    :value="itemsPerPage"
                    label="Items per page"
                    type="number"
                    min="-1"
                    max="15"
                    @input="itemsPerPage = parseInt($event, 10)"
                ></v-text-field>
                </div>
            </v-container>
            </v-card>
            <!--Dialog Start-->
            <v-dialog v-model="dialog" width="60%">
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                <v-toolbar flat dark class="indigo lighten-3">
                    <v-toolbar-title
                    >Import XLSX</v-toolbar-title
                    >
                </v-toolbar>
                <v-card-text>
                    <v-file-input
                    dense
                    outlined
                    placeholder="Select XLSX file..."
                    v-model="userFile"
                    @change="uploadXlsx"
                    ></v-file-input>
                    <v-btn
                    class="red lighten-3"
                    dark
                    block
                    @click="saveInDatabase"
                    :disabled="!cellValues.length"
                    >
                    <v-icon>mdi-content-save</v-icon>save in database
                    </v-btn>
                    <v-data-table
                    :headers="cellHeaders"
                    :items="cellValues"
                    :items-per-page="100"
                    ></v-data-table>
                </v-card-text>
                </v-container>
            </v-card>
            </v-dialog>
            <!--Dialog finish-->

            <!--editDialog start-->
            <v-dialog v-model="editDialog" width="60%">
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                <v-toolbar flat dark class="indigo lighten-3">
                    <v-toolbar-title
                    >Edit HBL Number</v-toolbar-title
                    >
                </v-toolbar>
                <v-card-text>
                    <v-text-field
                    v-model="editId"
                    placeholder="ID"
                    outlined
                    dense
                    disabled
                    ></v-text-field>
                    <v-text-field
                    v-model="editBlNumber"
                    placeholder="BL Number"
                    outlined
                    dense
                    ></v-text-field>
                    <v-text-field
                    v-model="editContainerNumber"
                    placeholder="Container Number"
                    outlined
                    dense
                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                    class="red lighten-3"
                    dark
                    block
                    @click="updateContainerNumber"
                    >
                    <v-icon>mdi-update</v-icon>Update
                    </v-btn>
                </v-card-actions>
                </v-container>
            </v-card>
            </v-dialog>
            <!--editDialog finish-->
          </v-card-text>
        </v-tab-item>
        <!-- Excel finish -->
      </v-tabs-items>


    </v-container>
  </v-card>
</template>
<script>
import moment from 'moment';

export default {
  data() {
    return {
      overlay: false,
      loading: false,
      search: null,
      pageCount: 0,
      itemsPerPage: 30,
      page: 1,
      dialog: false,
      editDialog: false,
      editId: null,
      editBlNumber: null,
      editContainerNumber: null,
      item: null,
      formData: new FormData(),
      hyperLinks: [],
      totalSizeLimit: 20000000,
      actualTotalSize: null,
      percentage2: 0,
      finishedUploading: false,
      tab: 'tab-2',
      userFile: null,
      cellHeaders: [
        {
          text: "BL NUMBER",
          value: "bl_number",
          sortable: false,
        },
        {
          text: "CONTAINER NUMBER",
          value: "container_number",
          sortable: false,
        }
      ],
      cellValues: [],
      headers: [
        {
          text: "BlNumber",
          value: "bl_number",
          sortable: true,
        },
        {
          text: "ContainerNumber",
          value: "container_number",
          sortable: true,
        },
        {
          text: "updated_at",
          value: "updated_at",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        }
      ],
      items: [],
    };
  },
  created() {
    if (this.$store.state.role !== "admin") {
      return false;
    }
    // this.axios.defaults.headers.common["Authorization"] =
    //   "Bearer " + this.$store.state.access_token;
    this.loadData();
  },
  computed: {
    percentage: function () {
      return Math.floor((this.actualTotalSize / this.totalSizeLimit) * 100);
    },
  },
  methods: {
    openFileDialog: function () {
      this.$refs.fileInput.click();
    },
    addFiles: function (files) {
      const self = this;
      let n = 0;
      // for (let file of this.formData) {
      //     n++;
      // }
      for (let file of files) {
        if (file.type == "") {
          const payload = {
            title: "Provide file only",
            msg: file.name + " does not seem to be a file...(directory..?)",
          };
          this.$store.commit("showAlert", payload);
          continue;
        }

        this.actualTotalSize += file.size;

        if (this.actualTotalSize > this.totalSizeLimit) {
          const payload = {
            title: "Total size of uploading files should be under 20MB",
            msg: "You can not upload any more file. aborted...",
          };
          self.$store.commit("showAlert", payload);

          this.actualTotalSize -= file.size;

          break;
        }

        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            resolve(e.target.result);
          };
          reader.readAsDataURL(file);
        }).then((result) => {
          const tmpData = result;
          const link =
            '<a href="' +
            tmpData +
            '" download="' +
            file.name +
            '">' +
            file.name +
            " (" +
            Math.floor(file.size / 1000) +
            "KB)" +
            "</a>";

          if (this.hyperLinks.indexOf(link) !== -1) {
            const payload = {
              title: "Duplicate entry",
              msg: "You tried to upload the same file! Aborted...",
            };
            self.$store.commit("showAlert", payload);
            this.actualTotalSize -= file.size;
          } else {
            /* Add links and objects ----------------------------------*/
            this.hyperLinks.push(link);
            this.formData.append("files[" + n + "]", file);
            n++;
            // for (let file of this.formData) {
            //     console.log(file);
            // }
            /*---------------------------------------------------------*/
          }
        });
      }
    },
    onDrop: function (e) {
      this.addFiles(e.dataTransfer.files);
    },
    onSelect: function () {
      this.addFiles(this.$refs.fileInput.files);
    },
    deleteFile: function (i) {
      const filename = this.hyperLinks[i].replace(
        /<("[^"]*"|'[^']*'|[^'">])*>/g,
        ""
      );
      if (confirm(`Is it ok to remove "${filename}" ?`)) {
        this.hyperLinks.splice(i, 1);
        this.formData.delete("files[" + i + "]");
      }

      let newFormData = new FormData();
      let j = 0;
      this.actualTotalSize = 0;
      for (let file of this.formData) {
        this.actualTotalSize += file[1].size;
        newFormData.append("files[" + j + "]", file[1]);

        j++;
      }
      this.formData = newFormData;
      //console.log(this.hyperLinks);
    },
    sendFiles: function () {
      // this.axios.defaults.headers.common["Authorization"] =
      //   "Bearer " + this.$store.state.access_token;
      // this.axios.defaults.headers.get["Accept"] = "application/json";
      // this.axios.defaults.headers.post["Accept"] = "application/json";

      this.overlay = true;
      this.formData.append("user_id", this.$store.state.user_id);
      this.formData.append("agent_code", this.$store.state.agent_code);
      this.axios
        .post(
          "/api/save_edis",
          this.formData,
          {
            onUploadProgress: this.onUpload,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          //console.log(response.data);
          const payload = {
            title: "Finished uploading EDIs",
            msg: "Finished uploading EDIs...",
          };
          this.$store.commit("showAlert", payload);
          this.$router.push("/admin/bl_numbers");
          this.hyperLinks = [];
          this.formData = new FormData();
          this.actualTotalSize = 0;
          this.overlay = false;
          this.percentage2 = 0;
          this.finishedUploading = true;
        });
    },
    onUpload: function (e) {
      this.percentage2 = Math.floor((e.loaded / e.total) * 100);
    },
    uploadXlsx: function() {
      // console.log(this.userFile);
      if(!this.userFile) {
        this.cellValues = [];
        return false;
      };

      this.formData = new FormData();
      this.formData.append('file', this.userFile);
      this.formData.append('email', this.$store.state.email);
      this.axios.post(
        '/api/import_excel_for_containers',
          this.formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
      )
        .then(response => {
          // console.log(response.data);
          this.cellValues = response.data;
        }).catch(error => {
          console.log(error);
        });
    },
    saveInDatabase: function() {
      if(!confirm(
        'Is it ok to save these values in the database table?'
      )) {
        this.cellValues = [];
        this.userFile = null;
        return false;
      }

      this.axios.post(
        '/api/save_in_database_for_shipping_line',
        {
          cellValues: this.cellValues
        }
      )
        .then(response => {
          if(response.status === 200) {
            const payload = {
              title: "XLSX data registered Successfully.",
              msg: "Those BL & HBL Numbers have been successfully registered in the database table.",
            };
            this.$store.commit("showAlert", payload);
            this.dialog = false;
            this.loadData();
          }
        }).catch(error => {
            const payload = {
              title: "Failed...",
              msg: "Server error occured due to wrong formatted data or duplicate entry..",
            };
            this.$store.commit("showAlert", payload);
            this.dialog = false;
            return false;
        });
    },
    loadData: function() {
      this.overlay = true;
      this.loading = true;
      this.axios
        .post("/api/get_containers", {
          agent_code: this.$store.state.agent_code,
        })
        .then((response) => {
            // console.log(response.data)
          this.items = response.data;
          this.overlay = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.overlay = false;
          this.loading = false;
        });
    },
    updateContainerNumber: function() {
      if (!confirm('Is it ok to update this record?')) {
        this.editDialog = false;
        return false;
      }

      this.axios.post(
        '/api/update_container_number',
        {
          id: this.editId,
          bl_number: this.editBlNumber,
          container_number: this.editContainerNumber
        }
      )
        .then(response => {
          // console.log(response.data);
          if ( response.status === 200 ) {
            const payload = {
              title: "Updated Successfully",
              msg: "The record has been updated successfully.",
            };
            this.$store.commit("showAlert", payload);
          }
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.editId = null;
          this.editBlNumber = null;
          this.editContainerNumber = null;
          this.editDialog = false;
          this.loadData();
        });
    },
    showContainerNumbers: function() {
      this.dialog = true;
      this.cellValues = [];
      this.userFile = null;
    },

    openEditContainerNumberDialog: function(item) {
      this.editDialog = true;
      this.editId = item.id;
      this.editBlNumber = item.bl_number;
      this.editContainerNumber = item.container_number;
    },
    updateContainerNumber: function() {
      if (!confirm('Is it ok to update this record?')) {
        this.editDialog = false;
        return false;
      }

      this.axios.post(
        '/api/update_hbl_number',
        {
          id: this.editId,
          bl_number: this.editBlNumber,
          hbl_number: this.editHblNumber
        }
      )
        .then(response => {
          // console.log(response.data);
          if ( response.status === 200 ) {
            const payload = {
              title: "Updated Successfully",
              msg: "The record has been updated successfully.",
            };
            this.$store.commit("showAlert", payload);
          }
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.editId = null;
          this.editBlNumber = null;
          this.editHblNumber = null;
          this.editDialog = false;
          this.loadData();
        });
    },
  },
  filters: {
    formatDate: function (date) {
      return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
    },
  },
};
</script>
<style scoped>
</style>
