<template>
  <div>
    <v-card>
      <v-container>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :search="search"
          fixed-header
          height="50vh"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:top>
            <v-toolbar flat color="indigo lighten-3" dark>
              <v-toolbar-title>Containers</v-toolbar-title>
            </v-toolbar>
            <v-text-field
              v-model="search"
              label="search"
              outlined
              class="my-3"
              append-icon="mdi-magnify"
              dense
            ></v-text-field>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            total-visible="10"
          ></v-pagination>
          <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div>
      </v-container>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      loading: false,
      search: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 30,
      headers: [
        {
          text: "ContainerNumber",
          value: "container_number",
          sortable: true,
        },
        {
          text: "BLNumber",
          value: "bl_number",
          sortable: true,
        },
        {
          text: "TransactionNumber",
          value: "transaction_number",
          sortable: true,
        },
        {
          text: "PullOutScheduleDate",
          value: "pullout_schedule_date",
          sortable: true,
        },
      ],
      items: [],
    };
  },
  created() {
    this.overlay = true;
    this.loading = true;
    // this.axios.defaults.headers.common["Authorization"] =
    //   "Bearer " + this.$store.state.access_token;
    this.axios
      .post("/api/get_containers", {})
      .then((response) => {
        this.items = response.data;
        this.overlay = false;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {},
};
</script>
